import { CMSApp } from '@cms/tpl';
import { ENDPOINTS, ENVS, ICONS, PAGES } from 'common';
import 'moment/locale/vi';
import React from 'react';
import './App.scss';
import store from './redux';
import './styles/customs.scss';

const reduxDispatch = (action) => {
  store.dispatch(action);
};

const App = () => {
  return (
    <CMSApp
      reduxDispatch={reduxDispatch}
      icons={ICONS}
      pages={PAGES}
      envs={ENVS}
      endpoints={ENDPOINTS}
    />
  );
};

export default App;
