import { HistoryInfo } from '@cms/tpl';
import React from 'react';
import UTILS from 'utils';

const HistoryBox = ({
  funcMoreInfo = (value) => [],
  showCreate = true,
  showModified = true,
  ...props
}) => {
  const { createdTime, modifiedTime, modifiedAt, createdAt } =
    props.value || {};
  const historyInfo = [
    ...(showCreate
      ? [
          {
            text: 'Ngày tạo',
            value:
              (createdTime && UTILS.DATE_TIME.toDate(createdTime)) ||
              'Không xác định',
          },
          { text: 'Người tạo', value: createdAt },
        ]
      : []),
    ...(showModified
      ? [
          {
            text: 'Ngày cập nhật',
            value:
              (modifiedTime && UTILS.DATE_TIME.toDate(modifiedTime)) ||
              'Không xác định',
          },
          { text: 'Người cập nhật', value: modifiedAt },
        ]
      : []),
    ...funcMoreInfo(props.value),
  ];

  return <HistoryInfo items={historyInfo} />;
};

export default HistoryBox;
