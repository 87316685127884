import { DownloadOutlined } from '@ant-design/icons';
import {
  DateTimePicker,
  EnumCheckbox,
  EnumRadiobox,
  EnumSelectbox,
  FormData,
  SelectSuggest,
  TextArea,
  UploadFile,
  usePrivilege,
} from '@cms/tpl';
import { Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ENVS, LINKS } from 'common';
import { HistoryBox } from 'components';
import InputSwitch from 'components/InputSwitch';
import ENUMS from 'enums';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OptionService from 'services/OptionService';
import UTILS from 'utils';
import Service from '../Service';

const Detail = ({ create = false, data = null, loading = false, monitor }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(loading);
  const [searchParams, setSearchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || undefined;
  const [form] = Form.useForm();

  const handleGetFile = () => {
    UTILS.FILE.download(`${API_PATHS.ADDENDUM.GET.FILE}?id=${data.addendumId}`);
  };
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const privilege = {
    isUpdate: usePrivilege('Update'),
    isUpdateEndBusinessTime: usePrivilege('UpdateEndBusiness'),
  };

  return (
    <React.Fragment>
      <FormData
        name='form-detail'
        loading={isLoading}
        form={form}
        allowEdit={privilege.isUpdate}
        initialValues={{
          status: 1,
          contractType: 1,
          businessType: 1,
          mgType: 1,
          contractId: contractId,
        }}
        set={(id, values) => {
          return Service.set(id, values).then(() => {
                monitor({});
          });
        }}
        onSetSuccess={(resp) => {
          create &&
            navigate(LINKS.toDetail(CONTAINER.ADDENDUM, resp.item?.addendumId));
        }}
        rows={FORM_ITEMS(create, privilege.isUpdateEndBusinessTime)}
        extendActions={
          !create && [
            {
              text: 'Tải phụ lục',
              type: 'default',
              icon: <DownloadOutlined />,
              action: handleGetFile,
            },
          ]
        }
      />
    </React.Fragment>
  );
};
const FORM_ITEMS = (create, isUpdateEndBusinessTime) => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Số phụ lục',
          name: 'addendumNumber',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input disabled={!create} />,
        },
        {
          label: 'Hợp đồng',
          name: 'contractId',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: (
            <SelectSuggest
              disabled={!create}
              suggestApi={OptionService.getContracts}
            />
          ),
        },
        {
          label: 'Ngày ký',
          name: 'signTime',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <DateTimePicker format='DD/MM/YYYY' />,
        },
        {
          label: 'Vô thời hạn',
          name: 'unlimited',
          input: <InputSwitch />,
        },
        {
          label: 'Thời hạn',
          name: 'period',
          requiredByItems: [{ key: 'unlimited', value: ['0', undefined] }],
          input: <Input type='number' step='0.1' />,
        },
        {
          label: 'Tự động gia hạn',
          name: 'autoRenew',
          requiredByItems: [{ key: 'unlimited', value: ['0', undefined] }],
          input: <InputSwitch />,
        },
        {
          label: 'Số năm gia hạn',
          name: 'yearExtend',
          requiredByItems: [{ key: 'autoRenew', value: ['1'] }],
          input: <Input type='number' step='1' />,
        },
        {
          label: 'Ngày hết hạn',
          name: 'expiredTime',
          requiredByItems: [{ key: 'unlimited', value: ['0', undefined] }],
          tooltip:
            'Được tự động tính khi tạo mới phụ lục hoặc thay đổi thời hạn hoặc đã hết hạn có năm gia hạn',
          input: <DateTimePicker format='DD/MM/YYYY' />,
        },
        {
          label: 'Loại phụ lục',
          name: 'signedTypes',
          input: <EnumCheckbox span={24} enums={ENUMS.AddendumSignType} />,
        },
        {
          label: 'Loại MGzc',
          name: 'mgzcType',
          input: <EnumSelectbox enums={ENUMS.AddendumMGZCType} />,
        },
        {
          label: 'Ngày thanh lý (NKD)',
          name: 'liquidationTime',
          input: (
            <DateTimePicker
              format='DD/MM/YYYY'
              disabled={create ? false : !isUpdateEndBusinessTime}
            />
          ),
        },
        {
          label: 'Số thanh lý',
          name: 'liquidationNumber',
          input: <Input />,
        },
        {
          label: 'Đã ký HĐĐT',
          name: 'eContractSigned',
          input: <InputSwitch />,
        },
        {
          label: 'Trạng thái',
          name: 'status',
          input: <EnumRadiobox enums={ENUMS.AddendumStatus} />,
        },
      ],
    },
    {
      size: 12,
      elements: [
        {
          label: 'File đính kèm',
          name: 'attachments',
          input: <UploadFile maxCount={10} action={`${ENVS.UPLOAD_MIXUS}`} />,
        },
        {
          label: 'Ghi chú',
          name: 'note',
          input: <TextArea />,
        },
        {
          label: ' ',
          colon: false,
          name: '_historyBox',
          notBeLongToForm: true,
          input: <HistoryBox />,
        },
      ],
    },
  ];

  return [{ cols }];
};

export default Detail;
