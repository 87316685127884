import {
  FormFilter,
  Portlet,
  PortletTable,
  SelectSuggest,
  UTILS,
  withAuthor,
} from '@cms/tpl';
import { Button, Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import { EnumTag } from 'components';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import Detail from './Detail';

const MediaIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  const [detail, setDetail] = useState({ open: false, id: 0, name: '' });
  return (
    <React.Fragment>
      <Portlet title='Tìm bài hát' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách bài hát'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='mediaId'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.MEDIA.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS(setDetail)}
        extendedAction={[
          <CreateButton setDetail={setDetail} />,
          <Button
            type='ghost'
            icon={ICONS.UploadOutlined}
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              urlSearchParams.append('tab', '1');
              navigate(
                `/${CONTAINER.EXPORT_MEDIA}?${urlSearchParams.toString()}`
              );
            }}
          >
            Xuất dữ liệu
          </Button>,
        ]}
      />
      <Detail
        detail={detail}
        setDetail={setDetail}
        setTableMonitor={setTableMonitor}
      />
    </React.Fragment>
  );
};

const CreateButton = withAuthor(({ setDetail }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        setDetail({ open: true, id: 0 });
      }}
    >
      Tạo bài hát
    </Button>
  );
}, 'Insert');

const FILTERS = [
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input placeholder='' />,
  },
  {
    label: 'Platform Content ID',
    name: 'mediaId',
    tooltip: 'ID nền tảng được gắn vào Media của Mixus',
    input: <SelectSuggest suggestApi={OptionService.getMediaMap} />,
  },
  {
    label: 'Tên bài hát',
    name: 'title',
    input: <Input placeholder='' />,
  },
  {
    label: 'Ca sĩ',
    name: 'artistId',
    input: <SelectSuggest suggestApi={OptionService.getArtists} />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractId',
    input: <SelectSuggest suggestApi={OptionService.getContracts} />,
  },
  {
    label: 'Đối tác',
    name: 'businessPartnerId',
    input: <SelectSuggest suggestApi={OptionService.getBusinessPartners} />,
  },
];
const COLUMNS = (setDetail) => [
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Nền tảng',
    dataIndex: 'platformTypes',
    render: (platformTypes) => {
      return (
        <div style={{ display: 'grid', gap: '6px' }}>
          {platformTypes &&
            String(platformTypes)
              .split(',')
              .map((value) => {
                return UTILS.ENUM.findByValue(ENUMS.PlatformType, value, 2);
              })}
        </div>
      );
    },
  },
  {
    title: 'Tên bài hát',
    dataIndex: 'title',
    render: (title, entry) => {
      return (
        <a
          onClick={() => {
            setDetail({ open: true, id: entry.mediaId, name: entry.title });
          }}
        >
          {title}
        </a>
      );
    },
  },
  {
    title: 'Ca sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Sáng tác',
    dataIndex: 'composerName',
  },
  {
    title: 'Số HĐ',
    dataIndex: ['contract', 'contractNumber'],
    width: 300,
    render: (value, entry) => {
      return (
        entry.contract?.contractNumber && (
          <ul style={{ display: 'inherit' }}>
            <li>
              <Link
                to={LINKS.toDetail(
                  CONTAINER.CONTRACT,
                  entry.contract.contractId
                )}
              >
                {value}
              </Link>
            </li>
            <li>
              {UTILS.ENUM.findByValue(
                ENUMS.ContractStatus,
                entry.contract.status,
                1
              )}
            </li>
          </ul>
        )
      );
    },
  },
  {
    title: 'Số PL',
    dataIndex: ['addendum', 'addendumNumber'],
    width: 190,
    render: (value, entry) => {
      return (
        entry.addendum?.addendumNumber && (
          <ul style={{ display: 'inherit' }}>
            <li>
              <Link
                to={LINKS.toDetail(
                  CONTAINER.ADDENDUM,
                  entry.addendum.addendumId
                )}
              >
                {value}
              </Link>
            </li>
            <li>
              {UTILS.ENUM.findByValue(
                ENUMS.AddendumStatus,
                entry.addendum.status,
                1
              )}
            </li>
          </ul>
        )
      );
    },
  },
  {
    title: 'Quyền liên quan',
    dataIndex: 'addendumMedia',
    render: (value, entry) => {
      return value.relatedRights && ICONS.CheckCircleOutlined;
    },
  },
  {
    title: 'Quyền tác giả',
    dataIndex: 'addendumMedia',
    render: (value, entry) => {
      return value.authorRights && ICONS.CheckCircleOutlined;
    },
  },
  {
    title: 'Trạng thái kinh doanh',
    dataIndex: ['addendumMedia', 'status'],
    render: (status) => {
      return (
        (status &&
          status > 0 &&
          UTILS.ENUM.findByValue(ENUMS.AddendumStatus, status, 2)) ||
        'Không xác định'
      );
    },
  },
  {
    title: 'Trạng thái Audio',
    dataIndex: 'audioStatus',
    render: (status) => {
      return <EnumTag enums={ENUMS.MediaStatus} value={status} />;
    },
  },
  {
    title: 'Trạng thái Video',
    dataIndex: 'videoStatus',
    render: (status) => {
      return <EnumTag enums={ENUMS.MediaStatus} value={status} />;
    },
  },
  {
    title: 'Ngày Release',
    dataIndex: 'releaseDate',
    render: (value) => UTILS.DATE_TIME.toDate(value),
  },
  {
    title: 'Xếp hạng',
    dataIndex: 'rank',
  },
];

export default MediaIndex;
