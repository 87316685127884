import { DownloadOutlined } from '@ant-design/icons';
import { PortletTable } from '@cms/tpl';
import { Button } from 'antd';
import { API_PATHS, ICONS } from 'common';
import moment from 'moment';
import JOB_STATUS from 'pages/exports/enums/JobStatus';
import React, { useEffect, useState } from 'react';
import UTILS from 'utils';
import Service from '../Services';

const ListJob = ({ type }) => {
  const [data, setData] = useState({ items: [], total: 0 });
  const [tableMonitor, setTableMonitor] = useState({});
  useEffect(() => {
    let fetching = false;
    let interval = setInterval(() => {
      // @ts-ignore
      if (document.visibilityState == 'visible' && !fetching) {
        fetching = true;
        Service.getJob(type)
          .then((data) => {
            setData({ ...data, items: data.items });
          })
          .finally(() => {
            fetching = false;
          });
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <React.Fragment>
      <PortletTable
        title=''
        icon={ICONS.BarsOutlined}
        hideSelectedRow
        columns={COLUMNS_LIST}
        tableMonitor={tableMonitor}
        sortable={false}
        onTableMonitor={() => setTableMonitor({})}
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.REVENUE_SETTINGS.GET.JOB + `?type=${type}`,
          filterByQs: false,
          loadPaging: false,
          showPaging: false,
        }}
      />
    </React.Fragment>
  );
};
const COLUMNS_LIST = [
  {
    title: 'ID',
    dataIndex: 'jobId',
    ellipsis: true,
  },
  {
    title: 'Tháng',
    dataIndex: 'data',
    ellipsis: true,
    render: (data) => {
      return JSON.parse(data)?.month || 'Không xác định';
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdTime',
    render: (createdTime) => UTILS.DATE_TIME.toDate(createdTime),
    ellipsis: true,
  },
  {
    title: 'Người tạo',
    dataIndex: 'userName',
    ellipsis: true,
  },
  {
    title: 'Thời gian hoàn thành',
    dataIndex: 'endTime',
    render: (endTime, record) => {
      if (endTime <= 0) {
        return '';
      }
      let total = endTime - record.createdTime;
      if (record.resumeTime > 0) {
        total = endTime - record.resumeTime;
      }
      const duration = moment.duration(total, 'seconds');
      const h = duration.hours();
      const m = duration.minutes();
      const s = duration.seconds();
      let text =
        `${h > 0 ? h + ' giờ ' : ''}` +
        `${m > 0 ? m + ' phút ' : ''}` +
        `${s > 0 ? s + ' giây ' : ''}`;
      return text;
    },
    ellipsis: true,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    ellipsis: true,
    render: (status, record) => {
      const progress = record.progress;
      const res =
        progress.total > 0
          ? `${progress.index}/${progress.total} - ${progress.percent}%`
          : UTILS.ENUM.findByValue(JOB_STATUS, status, 2);
      return res;
    },
  },
  {
    title: 'Nội dung nhập',
    dataIndex: 'linkData',
    width: 130,
    render: (link) => {
      return (
        link && (
          <Button
            style={{ padding: '0px', minWidth: '0px' }}
            href={link}
            icon={<DownloadOutlined />}
          />
        )
      );
    },
  },
  {
    title: 'Nội dung lỗi',
    dataIndex: 'fileId',
    width: 130,
    render: (fileId) => {
      return (
        fileId > 0 && (
          <Button
            style={{ padding: '0px', minWidth: '0px' }}
            onClick={() => {
              UTILS.FILE.download(
                `${API_PATHS.REVENUE_SETTINGS.GET.FILE}?id=${fileId}`
              );
            }}
            icon={<DownloadOutlined />}
          />
        )
      );
    },
  },
];

export default ListJob;
