import { CommonFormFilter, Portlet, PortletTable } from '@cms/tpl';
import { Divider, Form } from 'antd';
import { API_PATHS, ICONS } from 'common';
import React, { useEffect, useState } from 'react';
import ImportService from './Services';
import { COLUMNS_LIST, FILTER_ELEMENTS, ROW_KEY } from './Utils';

const ListJob = ({ container }) => {
  const [data, setData] = useState({ items: [], total: 0 });
  const [tableMonitor, setTableMonitor] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    let fetching = false;
    let interval = setInterval(() => {
      if (document.visibilityState == 'visible' && !fetching) {
        fetching = true;
        ImportService(container)
          .getPag(window.location.search)
          .then((data) => {
            setData({ ...data, items: data.items });
          })
          .finally(() => {
            fetching = false;
          });
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <React.Fragment>
      <Portlet loading={false} title='Tìm kiếm Job' icon={ICONS.SearchOutlined}>
        <CommonFormFilter
          name='Search form'
          form={form}
          autoFetch={true}
          items={FILTER_ELEMENTS}
          setTableMonitor={setTableMonitor}
          mapURLSearchParams={true}
        />
      </Portlet>
      <Divider />
      <PortletTable
        title='Danh sách Job'
        icon={ICONS.BarsOutlined}
        rowName={'jobName'}
        rowKey={ROW_KEY}
        columns={COLUMNS_LIST(container, setTableMonitor)}
        tableMonitor={tableMonitor}
        sortable={false}
        onTableMonitor={() => setTableMonitor({})}
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.IMPORT(container).GET.PAG,
          filterByQs: true,
        }}
      />
    </React.Fragment>
  );
};

export default ListJob;
