import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
const API = API_PATHS.ADDENDUM;
const Service = {
  get: (id) => HELPER.API.executeGet(`${API.GET.ITEM}?id=${id}`),
  set: (id, entity) => {
    let data = new URLSearchParams();
    data.append('entity', JSON.stringify(entity));
    data.append('id', id);
    return HELPER.API.executePostForm(
      id > 0 ? API.POST.SET : API.POST.ADD,
      data
    );
  },
  setMedias: (id, medias) => {
    let data = new URLSearchParams({ id, medias: JSON.stringify(medias) });
    return HELPER.API.executePostForm(API.POST.SET_MEDIAS, data);
  },
  setMediasStatus: (addendumId, status, ids) => {
    let data = new URLSearchParams({
      addendumId,
      status,
      ids: JSON.stringify(ids),
    });
    return HELPER.API.executePostForm(API.POST.SET_MEDIAS_STATUS, data);
  },
  delMedias: (id, medias) => {
    let data = new URLSearchParams({ id, medias: JSON.stringify(medias) });
    return HELPER.API.executePostForm(API.POST.DEL_MEDIAS, data);
  },
  setMediaRight: (addendumId, values) => {
    values.addendumId = addendumId;
    let data = new URLSearchParams(values);
    return HELPER.API.executePostForm(API.POST.SET_MEDIA_RIGHTS, data);
  },
  setMedaiSharingRatio: (addendumId, values) => {
    let data = new URLSearchParams({
      addendumId,
      mediaId: values.mediaId,
      ratio: values.sharingRatio,
    });
    return HELPER.API.executePostForm(API.POST.SET_MEDIA_SHARING_RATIO, data);
  },
  setMediaEndBusiness: (addendumId, values) => {
    let data = new URLSearchParams({
      addendumId,
      mediaId: values.mediaId,
      endBusinessTime: values.endBusinessTime,
    });
    return HELPER.API.executePostForm(API.POST.SET_MEDIA_END_BUSINESS, data);
  },
};
export default Service;
