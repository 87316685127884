import { EnumRadiobox, ImageBox, ModalFormData } from '@cms/tpl';
import { Form, Input } from 'antd';
import { ENVS } from 'common';
import ENUMS from 'enums';
import React, { useCallback, useEffect, useRef } from 'react';
import Service from './Service';

const Detail = ({
  detail = { open: false, id: 0, name: '' },
  setDetail,
  setTableMonitor = undefined,
}) => {
  const [form] = Form.useForm();
  const data = useRef(0);

  useEffect(() => {
    if (detail.id > 0) {
      Service.get(detail.id).then((resp) => {
        // @ts-ignore
        form.setFieldsValue(resp.item);
      });
    }

    data.current = detail.id;
  }, [detail.id]);

  const onSubmit = useCallback((value) => {
    return Service.set(data.current, value).then(() => {
      setTableMonitor({});
    });
  }, []);

  return (
    <React.Fragment>
      <ModalFormData
        title={detail.id > 0 ? 'Cập nhật: ' + detail.name : 'Thêm mới'}
        name='modal-form'
        visible={detail.open}
        initialValues={{ status: 1 }}
        form={form}
        onVisible={() => {
          form.resetFields();
          setDetail({ open: false, id: 0 });
        }}
        items={FORM_ITEM}
        destroyOnClose
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

const FORM_ITEM = [
  {
    label: 'Tên nghệ sĩ',
    name: 'name',
    rules: [{ required: true, message: 'Vui lòng nhập!' }],
    input: <Input />,
  },
  {
    label: 'Tên thật',
    name: 'realName',
    input: <Input />,
  },
  {
    label: 'Avatar',
    name: 'avatar',
    input: (
      <ImageBox
        header=''
        size={[500, 500]}
        type='avatar'
        edit
        endpoint={ENVS.DOMAIN_UPLOAD_IMAGE}
      />
    ),
  },
  {
    label: 'Trạng thái',
    name: 'status',
    input: <EnumRadiobox enums={ENUMS.Status} />,
  },
];

export default React.memo(Detail);
