import { ModalFormData, UploadFile } from '@cms/tpl';
import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { ENVS, ICONS } from 'common';
import MonthPicker from 'components/monthpicker/MonthPicker';
import React, { useState } from 'react';
import Service from '../Services';
import Headers from './header/Headers';
import './index.scss';
import ListJob from './ListJob';
const MG_TYPE = 7;
const MG_ZC_TYPE = 8;
const I_MG_TYPE = 9;
const I_MG_ZC_TYPE = 10;
const InputDebtIndex = () => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(MG_TYPE);
  const [form] = Form.useForm();
  return (
    <React.Fragment>
      <Card
        className='debt-card'
        title={
          <Row align='middle' gutter={9}>
            <Col>
              <Button
                type='primary'
                onClick={() => {
                  form.setFieldValue('type', I_MG_TYPE);
                  setType(MG_TYPE);
                  setVisible(true);
                }}
                icon={ICONS.DownloadOutlined}
              >
                Nhập hợp đồng MG
              </Button>
            </Col>
          </Row>
        }
      >
        <ListJob type={I_MG_TYPE} />
      </Card>
      <Divider />
      <Card
        className='debt-card'
        title={
          <Row align='middle' gutter={9}>
            <Col>
              <Button
                type='primary'
                onClick={() => {
                  form.setFieldValue('type', I_MG_ZC_TYPE);
                  setType(MG_ZC_TYPE);
                  setVisible(true);
                }}
                icon={ICONS.DownloadOutlined}
              >
                Nhập hợp đồng MG Zingchart
              </Button>
            </Col>
          </Row>
        }
      >
        <ListJob type={I_MG_ZC_TYPE} />
      </Card>

      <ModalFormData
        name='import-data'
        title={
          type == MG_TYPE ? `Nhập dữ liệu MG` : 'Nhập dữ liệu MG Zingchart'
        }
        key='import-data'
        visible={visible}
        destroyOnClose
        onVisible={setVisible}
        okText='Xác nhận'
        onSubmit={Service.setJob}
        form={form}
        items={[
          {
            label: 'Tháng',
            name: 'month',
            input: <MonthPicker />,
          },
          {
            name: 'type',
            input: <Input />,
            hidden: true,
          },
          {
            label: 'File dữ liệu (.csv|.txt|.xlsx)',
            name: 'files',
            input: (
              <UploadFile
                accept={'.csv,.txt,.xlsx'}
                action={ENVS.UPLOAD_STATIC}
                maxCount={1}
              />
            ),
          },
          {
            wrapperCol: { span: 24 },
            name: 'headers',
            notBelongToForm: true,
            input: (
              <Headers
                type={type}
                linkTemplate={
                  type == MG_TYPE
                    ? 'https://zmp3-static.zmdcdn.me/data/mixus/tpl-mg.xlsx'
                    : 'https://zmp3-static.zmdcdn.me/data/mixus/tpl-mg-zc.xlsx'
                }
              />
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default InputDebtIndex;
