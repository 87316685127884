import { DownloadOutlined } from '@ant-design/icons';
import {
  DateTimePicker,
  EnumCheckbox,
  EnumRadiobox,
  FormData,
  SelectSuggest,
  TextArea,
  UploadFile,
  usePrivilege,
} from '@cms/tpl';
import { Form, Input, InputNumber } from 'antd';
import { API_PATHS, CONTAINER, ENVS, LINKS } from 'common';
import { HistoryBox, SelectOptionAPI } from 'components';
import InputSwitch from 'components/InputSwitch';
import ENUMS from 'enums';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import UTILS from 'utils';
import Service from '../Service';

const Detail = ({ create = false, data = null, loading = false }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const handleGetFile = () => {
    UTILS.FILE.download(`${API_PATHS.CONTRACT.GET.FILE}?id=${data.contractId}`);
  };
  const privilege = {
    isUpdate: usePrivilege('Update'),
    isUpdateRatio: usePrivilege('UpdateRatio'),
  };

  return (
    <React.Fragment>
      <FormData
        name='form-detail'
        loading={loading}
        form={form}
        allowEdit={privilege.isUpdate}
        initialValues={{
          status: 1,
          contractType: 1,
          businessType: 1,
          mgType: 1,
        }}
        set={Service.set}
        onSetSuccess={(resp) => {
          create &&
            navigate(LINKS.toDetail(CONTAINER.CONTRACT, resp.item?.contractId));
        }}
        rows={FORM_ITEMS(create, privilege.isUpdateRatio)}
        extendActions={
          !create && [
            {
              text: 'Tải hợp đồng',
              type: 'default',
              icon: <DownloadOutlined />,
              action: handleGetFile,
            },
          ]
        }
      />
    </React.Fragment>
  );
};
const FORM_ITEMS = (create, isUpdateRatio) => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Số hợp đồng',
          name: 'contractNumber',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input disabled={!create} />,
        },
        {
          label: 'Ngày ký',
          name: 'signTime',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <DateTimePicker format='DD/MM/YYYY' />,
        },
        {
          label: 'Đối tác',
          name: 'businessPartnerId',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: (
            <SelectSuggest
              allowSearchEmpty
              suggestApi={OptionService.getBusinessPartners}
            />
          ),
        },
        {
          label: 'Hợp đồng tác giả',
          name: 'composerId',
          input: (
            <SelectSuggest
              allowSearchEmpty
              disabled={!create}
              suggestApi={OptionService.getArtists}
            />
          ),
        },
        {
          label: 'Loại hợp đồng',
          name: 'contractType',
          input: <EnumRadiobox enums={ENUMS.ContractType} />,
        },
        {
          label: 'Loại hình',
          name: 'businessType',
          input: <EnumRadiobox enums={ENUMS.ContractBusinessType} />,
        },
        {
          label: 'Loại phụ lục',
          name: 'signedTypes',
          input: <EnumCheckbox span={24} enums={ENUMS.ContractSignType} />,
        },
        {
          label: 'Số tiền MG',
          name: 'mgMoney',
          input: (
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => String(value).replace(/\$\s?|(,*)/g, '')}
            />
          ),
        },
        {
          label: 'Loại MG',
          name: 'mgType',
          input: <EnumRadiobox enums={ENUMS.ContractMGType} />,
        },
        {
          label: 'Đã ký HĐĐT',
          name: 'eContractSigned',
          input: <InputSwitch />,
        },
        {
          label: 'Trạng thái',
          name: 'status',
          input: <EnumRadiobox enums={ENUMS.ContractStatus} />,
        },
        {
          label: 'Tỉ lệ chia sẻ (%)',
          name: 'sharingRatio',
          rules: [{ required: true }],
          input: (
            <SelectOptionAPI
              getOptions={OptionService.getSharingRatio}
              disabled={create ? false : !isUpdateRatio}
            />
          ),
        },
      ],
    },
    {
      size: 12,
      elements: [
        {
          label: 'File đính kèm',
          name: 'attachments',
          input: (
            <UploadFile
              withCredentials
              maxCount={100}
              action={`${ENVS.UPLOAD_MIXUS}`}
            />
          ),
        },
        {
          label: 'Ghi chú',
          name: 'note',
          input: <TextArea />,
        },
        {
          label: ' ',
          colon: false,
          name: '_historyBox',
          notBeLongToForm: true,
          input: <HistoryBox />,
        },
      ],
    },
  ];

  return [{ cols }];
};

export default Detail;
