import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';

const InputSwitch = ({
  onChange = undefined,
  value = false,
  defaultValue = false,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Switch
      checked={checked}
      defaultChecked={defaultValue}
      onChange={onChange && onChange}
    />
  );
};

export default InputSwitch;
