import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
const API = API_PATHS.BUSSINESS_PARTNER;
const Service = {
  get: (id) => HELPER.API.executeGet(`${API.GET.ITEM}?id=${id}`),
  set: (id, entity) => {
    let data = new URLSearchParams();
    data.append('entity', JSON.stringify(entity));
    data.append('id', id);
    return HELPER.API.executePostForm(
      id > 0 ? API.POST.SET : API.POST.ADD,
      data
    );
  },
  setMapping: (id, ids, type) => {
    let data = new URLSearchParams({ id, ids, type });
    return HELPER.API.executePostForm(API.POST.SET_MAPPING, data);
  },
  setPickDelegate: (id, delegateId, isPicked) => {
    let data = new URLSearchParams({ id, delegateId, isPicked });
    return HELPER.API.executePostForm(API.POST.SET_PICK_DELEGATE, data);
  },
};
export default Service;
