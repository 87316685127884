const ENVS = {
  BASE_ENDPOINT: process.env.REACT_APP_BASE_ENDPOINT,
  APP_NAME: process.env.REACT_APP_NAME,
  IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
  IMAGE_UPLOAD_URL: process.env.REACT_APP_IMAGE_UPLOAD_URL,
  UPLOAD_STATIC: process.env.REACT_APP_UPLOAD_STATIC,
  DOMAIN_UPLOAD_IMAGE: process.env.REACT_APP_DOMAIN_UPLOAD_IMAGE,
  DOMAIN_UPLOAD_STATIC: process.env.REACT_APP_DOMAIN_UPLOAD_STATIC,
  STATIC_URL: process.env.REACT_APP_STATIC_URL,
  UPLOAD_MIXUS: process.env.REACT_APP_UPLOAD_MIXUS,
};

export default ENVS;
