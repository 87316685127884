const FILE = {
  download: (url) => {
    var a = document.createElement('a');
    if (a.click) {
      a.setAttribute('href', url);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } else {
      window.location = url;
    }
  },
};
export default FILE;
