import { UTILS } from '@cms/tpl';
import { Tag } from 'antd';
import React from 'react';
const DistributeType = {
  ZMP3_ADS: [1, 'ZMP3 Ads', <Tag color={'#9B4DE0'}>ZMP3 Ads</Tag>],
  INTER: [2, 'Quốc tế', <Tag color={UTILS.COLOR.GREEN}>Quốc tế</Tag>],
  RB: [3, 'R&B', <Tag color={UTILS.COLOR.YELLOW_ORANGE}>R&B</Tag>],
  PUBLIC_STREAMING: [
    4,
    'Public Streaming',
    <Tag color={UTILS.COLOR.ORANGE}>Public Streaming</Tag>,
  ],
  ZMP3_VIP: [5, 'ZMP3 Vip', <Tag color={'#FCC001'}>ZMP3 Vip</Tag>],
  ZMELODY: [6, 'ZMelody', <Tag color={'blue'}>ZMelody</Tag>],
  ZSTYLE: [7, 'ZStyle', <Tag color={'blue'}>ZStyle</Tag>],
};
export default DistributeType;
