import {
  DateRangePicker,
  EnumSelectbox,
  FormFilter,
  Portlet,
  PortletTable,
  SelectSuggest,
  UTILS,
  withAuthor,
} from '@cms/tpl';
import { Button, Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';

const AddendumIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  return (
    <React.Fragment>
      <Portlet title='Tìm phụ lục' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách phụ lục'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='addendumId'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.ADDENDUM.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        extendedAction={[<CreateButton navigate={navigate} />]}
      />
    </React.Fragment>
  );
};
const CreateButton = withAuthor(({ navigate }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        navigate(LINKS.toCreate(CONTAINER.ADDENDUM));
      }}
    >
      Tạo phụ lục
    </Button>
  );
}, 'Insert');
const FILTERS = [
  {
    label: 'Số phụ lục',
    name: 'addendumNumber',
    input: <Input placeholder='' />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractId',
    input: <SelectSuggest suggestApi={OptionService.getContracts} />,
  },
  {
    label: 'Ngày ký',
    name: 'signRange',
    input: <DateRangePicker />,
  },
  {
    label: 'Ngày hết hạn',
    name: 'expiredRange',
    input: <DateRangePicker />,
  },
  {
    label: 'Trạng thái',
    name: 'status',
    input: <EnumSelectbox enums={ENUMS.AddendumStatus} />,
  },
  {
    label: 'File Scan',
    name: 'hasAttachment',
    input: <EnumSelectbox enums={ENUMS.YES_NO} addOptionAll />,
  },
];
const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'addendumId',
  },
  {
    title: 'Số phụ lục',
    dataIndex: 'addendumNumber',
    render: (addendumNumber, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.ADDENDUM, entry.addendumId)}>
          {addendumNumber}
        </Link>
      );
    },
  },
  {
    title: 'Số hợp đồng',
    dataIndex: ['contract', 'contractNumber'],
  },
  {
    title: 'Đối tác',
    dataIndex: ['businessPartner', 'legalName'],
  },
  {
    title: 'Ngày ký',
    dataIndex: 'signTime',
    render: (value) => UTILS.DATE_TIME.toDate(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Ngày hết hạn',
    dataIndex: 'expiredTime',
    render: (value) => UTILS.DATE_TIME.toDate(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Số bài hát',
    dataIndex: 'mediaCount',
  },
  {
    title: 'Đã ký HĐĐT',
    dataIndex: 'eContractSigned',
    render: (value) => UTILS.ENUM.findByValue(ENUMS.BOOL, value, 1),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.AddendumStatus, status, 2);
    },
  },
];

export default AddendumIndex;
