import {
  DateTimePicker,
  EnumSelectbox,
  FormAdd,
  ModalForm,
  SelectSuggest,
  UTILS,
  usePrivilege,
} from '@cms/tpl';
import { Button, Form, Input, Radio, Switch } from 'antd';
import { API_PATHS, ICONS } from 'common';
import { EnumTag, SelectOptionAPI } from 'components';
import ENUMS from 'enums';
import React, { useEffect, useRef, useState } from 'react';
import OptionService from 'services/OptionService';
import COLOR from 'utils/Color';
import Service from '../Service';

const MediaTab = ({ addendumId, contract }) => {
  const [tableMonitor, setTableMonitor] = useState({});
  const [modal, setModal] = useState(false);
  const [formModal] = Form.useForm();
  const [ratioModal, setRatioModal] = useState(false);
  const [ratioFormModal] = Form.useForm();
  const [endBusinessModal, setEndBusinessModal] = useState(false);
  const [endBusinessFormModal] = Form.useForm();
  const values = useRef(new Map());

  useEffect(() => {
    setTableMonitor({});
  }, [contract]);
  
  const setValues = (entry, name, value) => {
    let data = entry;
    data[name] = value;
    values.current.set(entry.mediaId, data);
  };

  const privilege = {
    isUpdate: usePrivilege('Update'),
    isCreate: usePrivilege('Insert'),
    isDelete: usePrivilege('Delete'),
    isUpdateRatio: usePrivilege('UpdateRatio'),
    isUpdateEndBusiness: usePrivilege('UpdateEndBusiness'),
  };

  const onClickUpdateRatio = (ratio, mediaId) => {
    ratioFormModal.setFieldValue('sharingRatio', ratio / 100);
    ratioFormModal.setFieldValue('mediaId', mediaId);
    setRatioModal(true);
  };

  const onClickUpdateEndBusiness = (endBusinessTime, mediaId) => {
    endBusinessFormModal.setFieldValue('endBusinessTime', endBusinessTime);
    endBusinessFormModal.setFieldValue('mediaId', mediaId);
    setEndBusinessModal(true);
  };
  return (
    <React.Fragment>
      {addendumId > 0 && (
        <React.Fragment>
          <FormAdd
            rowKey='mediaId'
            rowName='title'
            tableMonitor={tableMonitor}
            setTableMonitor={setTableMonitor}
            allowSave={privilege.isUpdate}
            allowDelete={privilege.isDelete}
            onSave={(list) => {
              let data = Array.from(values.current.values());
              list.forEach((item) => {
                let find = data.find((f) => f.mediaId === item.mediaId);
                if (!find) {
                  data.push({ mediaId: item.mediaId });
                }
              });
              return Service.setMedias(addendumId, data).then(() => {
                values.current.clear();
              });
            }}
            saveAndReload
            onDelete={(ids) => {
              return Service.delMedias(addendumId, ids);
            }}
            sortable={false}
            search={{
              title: 'Tìm và thêm bài hát',
              endpoint: API_PATHS.ADDENDUM.GET.PAG_MEDIA,
              filterForm: FILTERS,
              tableColumn: COLUMNS_SEARCH,
            }}
            data={{
              title: 'Danh sách bài hát',
              endpoint: `${API_PATHS.ADDENDUM.GET.MEDIAS}?id=${addendumId}`,
              tableColumn: COLUMNS_DATA(
                setValues,
                contract.contractType,
                privilege.isUpdateRatio,
                onClickUpdateRatio,
                privilege.isUpdateEndBusiness,
                onClickUpdateEndBusiness
              ),
            }}
            extButtons={
              privilege.isUpdate && [
                {
                  name: 'Cập nhật quyền cho tất cả',
                  style: { backgroundColor: COLOR.PUBLIC },
                  onClick: () => setModal(true),
                  icon: ICONS.SettingOutlined,
                  type: 'primary',
                },
                {
                  name: 'Kinh doanh',
                  style: { backgroundColor: COLOR.APPROVE },
                  onAction: {
                    confirm: true,
                    onClick: (value) => {
                      return Service.setMediasStatus(addendumId, 1, value).then(
                        () => setTableMonitor({})
                      );
                    },
                  },
                  icon: ICONS.Show,
                  type: 'primary',
                },
                {
                  name: 'Ngừng kinh doanh',
                  style: { backgroundColor: COLOR.HIDE },
                  onAction: {
                    confirm: true,
                    onClick: (value) => {
                      return Service.setMediasStatus(addendumId, 2, value).then(
                        () => setTableMonitor({})
                      );
                    },
                  },
                  icon: ICONS.Hide,
                  type: 'primary',
                },
              ]
            }
          />
          <ModalForm
            form={formModal}
            name='form-rights'
            title='Cập nhật quyền cho tất cả Media'
            visible={modal}
            onVisible={setModal}
            destroyOnClose
            onFinished={() => {
              formModal.resetFields();
            }}
            onSubmit={(values) => {
              return Service.setMediaRight(addendumId, values).then(() => {
                setTableMonitor({});
              });
            }}
            items={[
              {
                label: 'Quyền Audio',
                name: 'audioRights',
                input: <EnumSelectbox enums={ENUMS.YES_NO} />,
              },
              {
                label: 'Quyền Video',
                name: 'videoRights',
                input: <EnumSelectbox enums={ENUMS.YES_NO} />,
              },
              {
                label: 'Quyền liên quan',
                name: 'relatedRights',
                input: <EnumSelectbox enums={ENUMS.YES_NO} />,
              },
              {
                label: 'Quyền tác giả',
                name: 'authorRights',
                input: <EnumSelectbox enums={ENUMS.YES_NO} />,
              },
              {
                label: 'Loại độc quyền',
                name: 'monopolyType',
                input: <EnumSelectbox enums={ENUMS.MonopolyType} />,
              },
              {
                label: ' ',
                colon: false,
                input: (
                  <h3 style={{ color: '#E58F2B' }}>
                    <i>
                      Lưu ý: Để trống lựa chọn nếu muốn giữ nguyên thiết lập
                      hiện tại!
                    </i>
                  </h3>
                ),
              },
            ]}
          />
          <ModalForm
            form={ratioFormModal}
            name='form-ratio'
            title='Cập nhật tỉ lệ chia sẻ cho Media'
            visible={ratioModal}
            onVisible={setRatioModal}
            destroyOnClose
            onFinished={() => {
              // ratioFormModal.resetFields();
            }}
            onSubmit={(values) => {
              return Service.setMedaiSharingRatio(addendumId, values).then(
                () => {
                  setTableMonitor({});
                }
              );
            }}
            items={[
              {
                label: 'Mixus Id',
                name: 'mediaId',
                rules: [{ required: true }],
                input: <Input disabled={true} />,
                hidden: true,
              },
              {
                label: 'Tỉ lệ chia sẻ (%)',
                name: 'sharingRatio',
                rules: [{ required: true }],
                input: (
                  <SelectOptionAPI
                    getOptions={OptionService.getOldSharingRatio}
                    disabled={!privilege.isUpdateRatio}
                  />
                ),
              },
            ]}
          />
          <ModalForm
            form={endBusinessFormModal}
            name='form-ratio'
            title='Cập nhập ngày ngừng kinh doanh'
            visible={endBusinessModal}
            onVisible={setEndBusinessModal}
            destroyOnClose
            onFinished={() => {}}
            onSubmit={(values) => {
              return Service.setMediaEndBusiness(addendumId, values).then(
                () => {
                  setTableMonitor({});
                }
              );
            }}
            items={[
              {
                label: 'Mixus Id',
                name: 'mediaId',
                rules: [{ required: true }],
                input: <Input disabled={true} />,
                hidden: true,
              },
              {
                label: 'Ngày ngừng kinh doanh',
                name: 'endBusinessTime',
                rules: [{ required: true }],
                input: <DateTimePicker format='DD-MM-YYYY' />,
              },
            ]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const FILTERS = [
  {
    label: 'Tên bài hát',
    name: 'title',
    input: <Input placeholder='' />,
  },
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input placeholder='' />,
  },
  {
    label: 'Ca sĩ',
    name: 'artistId',
    input: <SelectSuggest suggestApi={OptionService.getArtists} />,
  },
  {
    label: 'Nhạc sĩ',
    name: 'composerId',
    input: <SelectSuggest suggestApi={OptionService.getArtists} />,
  },
];
const COLUMNS_SEARCH = [
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Tên bài hát',
    dataIndex: 'title',
  },
  {
    title: 'Ca sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Nhạc sĩ',
    dataIndex: 'composerName',
  },
  {
    title: 'Trạng thái Audio',
    dataIndex: 'audioStatus',
    render: (status) => {
      return <EnumTag enums={ENUMS.MediaStatus} value={status} />;
    },
  },
  {
    title: 'Trạng thái Video',
    dataIndex: 'videoStatus',
    render: (status) => {
      return <EnumTag enums={ENUMS.MediaStatus} value={status} />;
    },
  },
];

const COLUMNS_DATA = (
  setValues,
  contractType,
  isUpdateRatio,
  onClickUpdateRatio,
  isUpdateEndBusiness,
  onClickUpdateEndBusiness
) => {
  const cols = [
    {
      title: 'Mixus ID',
      dataIndex: 'mediaId',
    },
    {
      title: 'Tên bài hát',
      dataIndex: 'title',
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'artistName',
    },
    {
      title: 'Nhạc sĩ',
      dataIndex: 'composerName',
    },
    {
      title: 'Quyền Audio',
      dataIndex: 'audioRights',
      render: (audioRights, entry) => {
        return (
          <Switch
            defaultChecked={audioRights}
            onChange={(e) => {
              setValues(entry, 'audioRights', e);
            }}
          />
        );
      },
    },
    {
      title: 'Quyền Video',
      dataIndex: 'videoRights',
      render: (videoRights, entry) => {
        return (
          <Switch
            defaultChecked={videoRights}
            onChange={(e) => {
              setValues(entry, 'videoRights', e);
            }}
          />
        );
      },
    },
    {
      title: 'Quyền liên quan',
      dataIndex: 'relatedRights',
      render: (relatedRights, entry) => {
        return (
          <Switch
            defaultChecked={relatedRights}
            onChange={(e) => {
              setValues(entry, 'relatedRights', e);
            }}
          />
        );
      },
    },
    {
      title: 'Quyền tác giả',
      dataIndex: 'authorRights',
      render: (authorRights, entry) => {
        return (
          <Switch
            defaultChecked={authorRights}
            onChange={(e) => {
              setValues(entry, 'authorRights', e);
            }}
          />
        );
      },
    },

    {
      title: 'Trạng thái kinh doanh',
      dataIndex: 'status',
      render: (status) => {
        return (
          UTILS.ENUM.findByValue(ENUMS.AddendumStatus, status, 2) ||
          'Không xác định'
        );
      },
    },
    {
      title: 'Trạng thái Audio',
      dataIndex: ['media', 'audioStatus'],
      render: (status) => {
        return UTILS.ENUM.findByValue(ENUMS.MediaStatus, status, 1);
      },
    },
    {
      title: 'Trạng thái Video',
      dataIndex: ['media', 'videoStatus'],
      render: (status) => {
        return UTILS.ENUM.findByValue(ENUMS.MediaStatus, status, 1);
      },
    },
    {
      title: 'Tỉ lệ chia sẻ (%)',
      dataIndex: 'sharingRatio',
      render: (ratio, entry) => {
        if (isUpdateRatio) {
          return (
            <Button onClick={() => onClickUpdateRatio(ratio, entry.mediaId)}>
              {ratio}
            </Button>
          );
        }
        return <div>{ratio}</div>;
      },
    },
    {
      title: 'Ngày NKD',
      dataIndex: 'endBusinessTime',
      width: 110,
      render: (endBusinessTime, entry) => {
        if (isUpdateEndBusiness) {
          return (
            <Button
              onClick={() =>
                onClickUpdateEndBusiness(endBusinessTime, entry.mediaId)
              }
            >
              {endBusinessTime > 0
                ? UTILS.DATE_TIME.toDate(endBusinessTime, 'DD-MM-YYYY')
                : 'NaN'}
            </Button>
          );
        }
        return endBusinessTime > 0
          ? UTILS.DATE_TIME.toDate(endBusinessTime, 'DD-MM-YYYY')
          : 'NaN';
      },
    },
    // {
    //   title: 'Ngày public Video',
    //   dataIndex: 'publicVideoTime',
    //   width: 200,
    //   render: (publicVideoTime, entry) => {
    //     return (
    //       <DateTimePicker
    //         defaultValue={publicVideoTime}
    //         onChange={(value) => {
    //           setValues(entry, 'publicVideoTime', value);
    //         }}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: 'Ngày hết hạn',
    //   dataIndex: 'endTime',
    //   render: (endTime) => {
    //     // @ts-ignore
    //     return UTILS.DATE_TIME.toDate(endTime);
    //   },
    // },
  ];
  if (contractType == 1) {
    cols.push({
      title: 'Loại độc quyền',
      dataIndex: 'monopolyType',
      width: 250,
      render: (monopolyType, entry) => {
        const options = Object.entries(ENUMS.MonopolyType).map((e) => {
          return { label: e[1][1], value: e[1][0] };
        });
        return (
          <Radio.Group
            // @ts-ignore
            options={options}
            onChange={(e) => {
              setValues(entry, 'monopolyType', e.target.value);
            }}
            defaultValue={monopolyType}
            optionType='button'
            buttonStyle='solid'
          />
        );
      },
    });
  }
  return cols;
};

export default MediaTab;
