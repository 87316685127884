import { Card, Col, DatePicker, Row } from 'antd';
import locale from 'antd/lib/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './index.scss';
import Settings from './settings/Settings';
const FORMAT = 'YYYYMM';
const InputRevenueIndex = () => {
  const currentMonth = moment();
  const params = new URLSearchParams(location.search);
  const month = params.get('month');
  const defMonth = month && moment(month, FORMAT);
  const [value, setValue] = useState(defMonth || currentMonth);
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('month', value.format(FORMAT));
    window.history.replaceState(null, null, url);
  }, [value]);
  return (
    <Card
      className='revenue-settings'
      title={
        <Row align='middle' gutter={9}>
          <Col>Doanh thu tháng:</Col>
          <Col>
            <DatePicker
              key={'month-pick'}
              onChange={setValue}
              value={value}
              picker='month'
              locale={locale}
            />
          </Col>
        </Row>
      }
      loading={false}
    >
      <Settings month={value.format(FORMAT)} />
    </Card>
  );
};

export default InputRevenueIndex;
