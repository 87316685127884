const AddendumMGZCType = {
  ZC: [1, 'ZC'],
  ZC2: [2, 'ZC2'],
  ZC3a: [3, 'ZC3a'],
  ZC4_1: [4, 'ZC4.1'],
  ZC4_2: [5, 'ZC4.2'],
  ZC4a: [6, 'ZC4a'],
};

export default AddendumMGZCType;
