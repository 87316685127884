import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
const API = API_PATHS.CONTRACT;
const Service = {
  get: (id) => HELPER.API.executeGet(`${API.GET.ITEM}?id=${id}`),
  set: (id, entity) => {
    let data = new URLSearchParams();
    data.append('entity', JSON.stringify(entity));
    data.append('id', id);
    return HELPER.API.executePostForm(
      id > 0 ? API.POST.SET : API.POST.ADD,
      data
    );
  },
};
export default Service;
