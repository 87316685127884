import { isEmpty } from 'lodash';

const FORMAT = {
  numberWithCommas: (value) => {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  toYYYYMMDD: (value) => {
    let year = value.substring(0, 4);
    let month = value.substring(4, 6);
    let day = value.substring(6, 8);
    return `${day}-${month}-${year}`;
  },
  toArray: (value) => {
    if (isEmpty(value)) {
      return [];
    }
    return String(value)
      .split(',')
      .filter((f) => f.length > 0);
  },
  removeJsonValueEmpty: (obj) => {
    return JSON.parse(JSON.stringify(obj), (key, value) =>
      value === null || value === '' ? undefined : value
    );
  },
};

export default FORMAT;
