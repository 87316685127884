import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
import UTILS from 'utils';

const ExportService = (container) => {
  return {
    getPag: (search) =>
      HELPER.API.executeGet(`${API_PATHS.EXPORT(container).GET.PAG}${search}`),
    add: (id, entity) => {
      let data = new URLSearchParams();
      var params = {};
      params.headers = entity.headers;
      params.separate = entity.separate;
      params.linkData = entity.linkData;
      params.filters = entity;
      delete params.filters?.headers;
      delete params.filters?.separate;
      delete params.filters?.linkData;

      data.append(
        'entity',
        JSON.stringify(UTILS.FORMAT.removeJsonValueEmpty(params))
      );
      return HELPER.API.executePostForm(
        API_PATHS.EXPORT(container).POST.ADD,
        data
      );
    },
    getHeaders: () =>
      HELPER.API.executeGet(`${API_PATHS.EXPORT(container).GET.HEADERS}`),
    kill: (jobId) => {
      let data = new URLSearchParams();
      data.append('jobId', jobId);
      return HELPER.API.executePostForm(
        API_PATHS.EXPORT(container).POST.KILL,
        data
      );
    },
    resume: (jobId) => {
      let data = new URLSearchParams();
      data.append('jobId', jobId);
      return HELPER.API.executePostForm(
        API_PATHS.EXPORT(container).POST.RESUME,
        data
      );
    },
    getDataPreview: (entity) => {
      let data = new URLSearchParams();
      var params = {};
      params.headers = entity.headers;
      params.separate = entity.separate;
      params.linkData = entity.linkData;
      params.filters = entity;
      delete params.filters?.headers;
      delete params.filters?.separate;
      delete params.filters?.linkData;

      data.append(
        'entity',
        JSON.stringify(UTILS.FORMAT.removeJsonValueEmpty(params))
      );
      return HELPER.API.executeGet(
        `${API_PATHS.EXPORT(container).GET.PREVIEW}?${data.toString()}`
      );
    },
  };
};

export default ExportService;
