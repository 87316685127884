import { Portlet, TabPortlet } from '@cms/tpl';
import { ICONS } from 'common';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Service from '../Service';
import AddendumTab from './AddendumTab';
import Detail from './Detail';

const ContractDetail = ({ created = false }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ delegateId: '' });
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (!created) {
      setLoading(true);
      Service.get(id)
        .then((resp) => {
          // @ts-ignore
          setData(resp.item);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <React.Fragment>
      {created ? (
        <Portlet title='Tạo hợp đồng' icon={ICONS.PlusOutlined}>
          <Detail create={true} />
        </Portlet>
      ) : (
        <TabPortlet
          renderTabs={[
            {
              title: 'Thông tin hợp đồng',
              body: <Detail loading={loading} data={data} create={created} />,
            },
            {
              title: 'Danh sách phụ lục',
              body: <AddendumTab contractId={id} />,
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default ContractDetail;
