import ENVS from './ENVS';
const BASE_ENDPOINT = ENVS.BASE_ENDPOINT;

const ENDPOINTS = {
  entity: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}`,
  entityPag: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/get-pag`,
  entityAll: (entity) =>
    `${BASE_ENDPOINT}/v1.0/${entity}/get-pag?start=0&size=0`,
  entityTree: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/get-tree`,
  entityGet: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/get`,
  entityAdd: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/add`,
  entitySet: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/set`,
  entityDel: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/del`,
  entityDelMul: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/del-mul`,
  entitySetMul: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/set-mul`,
  entitySetStatus: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/set-status`,
  entitySetMulStatus: (entity) =>
    `${BASE_ENDPOINT}/v1.0/${entity}/set-mul-status`,
  entitySearch: (entity) => `${BASE_ENDPOINT}/v1.0/${entity}/get-search`,
};

export default ENDPOINTS;
