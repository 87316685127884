import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
const MonthPicker = ({ value = undefined, onChange = undefined }) => {
  const [month, setMonth] = useState();
  const [val, setVal] = useState(undefined);
  const monthOnChange = (month, value) => {
    setMonth(value.replace('-', ''));
  };
  useEffect(() => {
    onChange && onChange(month);
  }, [month]);
  useEffect(() => {
    if (value) {
      onChange(value);
      setVal(moment(value, 'YYYYMM'));
    } else {
      onChange('');
      setVal('');
    }
  }, [value]);
  return (
    <DatePicker
      key={'month-pick'}
      size='large'
      value={val}
      onChange={monthOnChange}
      picker='month'
      locale={locale}
    />
  );
};

export default MonthPicker;
