import { EnumSelectbox, ModalFormData, SelectSuggest } from '@cms/tpl';
import { Form, Input } from 'antd';
import ENUMS from 'enums';
import React, { useCallback, useEffect, useRef } from 'react';
import OptionService from 'services/OptionService';
import Service from './Service';

const Detail = ({
  detail = { open: false, id: 0, name: '' },
  setDetail,
  setTableMonitor,
}) => {
  const [form] = Form.useForm();
  const data = useRef(0);

  useEffect(() => {
    if (detail.id > 0) {
      Service.get(detail.id).then((resp) => {
        // @ts-ignore
        form.setFieldsValue(resp.item);
      });
    }

    data.current = detail.id;
  }, [detail.id]);

  const onSubmit = useCallback((value) => {
    return Service.set(data.current, value).then(() => {
      setTableMonitor({});
    });
  }, []);

  return (
    <React.Fragment>
      <ModalFormData
        title={detail.id > 0 ? 'Cập nhật: ' + detail.name : 'Thêm mới'}
        name='modal-form'
        visible={detail.open}
        initialValues={{ status: 1 }}
        form={form}
        onVisible={() => {
          form.resetFields();
          setDetail({ open: false, id: 0 });
        }}
        items={FORM_ITEM}
        destroyOnClose
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

const FORM_ITEM = [
  {
    label: 'Tên bài hát',
    name: 'title',
    rules: [{ required: true, message: 'Vui lòng nhập!' }],
    input: <Input />,
  },
  {
    label: 'Ca sĩ',
    name: 'artistIds',
    input: <SelectSuggest multiple suggestApi={OptionService.getArtists} />,
  },
  {
    label: 'Sáng tác',
    name: 'composerIds',
    input: <SelectSuggest multiple suggestApi={OptionService.getArtists} />,
  },
  {
    label: 'Trạng thái Audio',
    name: 'audioStatus',
    input: <EnumSelectbox enums={ENUMS.MediaStatus} />,
  },
  {
    label: 'Trạng thái Video',
    name: 'videoStatus',
    input: <EnumSelectbox enums={ENUMS.MediaStatus} />,
  },
  {
    label: 'Nền tàng',
    name: 'platforms',
    rules: [{ required: true, message: 'Vui lòng nhập!' }],
    input: <EnumSelectbox mode='multiple' enums={ENUMS.PlatformType} />,
  },
  {
    label: 'ZingMP3 ID',
    name: 'zmp3Id',
    rules: [{ required: true, message: 'Vui lòng nhập!' }],
    requiredByItems: [{ key: 'platforms', value: ['1', '1,2', '2,1'] }],
    input: <Input type='number' placeholder='1138768568' />,
  },
  {
    label: 'Orchard ID',
    name: 'orchardId',
    tooltip: 'ID cách nhau bằng dấu phẩy',
    rules: [{ required: true, message: 'Vui lòng nhập!' }],
    requiredByItems: [{ key: 'platforms', value: ['2', '1,2', '2,1'] }],
    input: <Input placeholder='DW10012, KS10041' />,
  },
];

export default React.memo(Detail);
