import { CONTAINER } from 'common';
import React from 'react';
import ImportCommonIndex from '../index';
import { COMMON_ELEMENTS } from '../Utils';

const elements = () => {
  const cols = [
    COMMON_ELEMENTS(CONTAINER.IMPORT_ADDENDUM_MEDIA),
    {
      size: 12,
      elements: [],
    },
  ];
  return [{ cols }];
};
const ImportAddendumMediaIndex = () => {
  return (
    <ImportCommonIndex
      formElements={elements()}
      title={'Media phụ lục'}
      container={CONTAINER.IMPORT_ADDENDUM_MEDIA}
    />
  );
};

export default ImportAddendumMediaIndex;
