import { DownloadOutlined } from '@ant-design/icons';
import { EnumSelectbox, SelectSuggest, TextArea, UploadFile } from '@cms/tpl';
import { Button, Modal } from 'antd';
import { API_PATHS, ENVS, ICONS } from 'common';
import moment from 'moment';
import JOB_STATUS from 'pages/exports/enums/JobStatus';
import React from 'react';
import OptionService from 'services/OptionService';
import UTILS from 'utils';
import COLOR from 'utils/Color';
import Headers from './components/Headers';
import ImportService from './Services';

const ROW_KEY = 'jobId';

const ActionButton = ({ jobId, status, container, setTableMonitor }) => {
  let color;
  let icon;
  let title;
  let service;
  switch (status) {
    case 2: //RUNNING
      color = COLOR.DELETE;
      title = 'Dừng';
      icon = ICONS.Stop;
      break;
    case 4: //FAILED
      color = COLOR.REFRESH;
      title = 'Chạy lại';
      icon = ICONS.Refresh;
      break;
    case 5: //KILLED
      color = COLOR.REFRESH;
      title = 'Tiếp tục';
      icon = ICONS.Refresh;
      break;
    case 1: //PENDING
    case 3: //FINISHED
    default:
      return <></>;
  }
  return (
    <Button
      onClick={() => {
        Modal.confirm({
          title: `Xác nhận`,
          icon: ICONS.ExclamationCircleOutlined,
          content: `${title} Job ${jobId}`,
          okText: 'Thực hiện',
          cancelText: 'Hủy',
          onOk: () => {
            if (title == 'Dừng') {
              ImportService(container)
                .kill(jobId)
                .finally(() => {
                  setTableMonitor({});
                });
            } else {
              ImportService(container)
                .resume(jobId)
                .finally(() => {
                  setTableMonitor({});
                });
            }
          },
        });
      }}
      type={'primary'}
      style={{
        backgroundColor: color,
        borderColor: color,
      }}
      icon={icon}
    >
      {title}
    </Button>
  );
};

const FILTER_ELEMENTS = [
  {
    label: 'Người tạo',
    name: 'createdBy',
    key: 'createdByFilter',
    input: <SelectSuggest suggestApi={OptionService.getAccounts} />,
  },
  {
    label: 'Trạng thái',
    name: 'status',
    input: <EnumSelectbox enums={JOB_STATUS} />,
  },
];

const COLUMNS_LIST = (container, setTableMonitor) => [
  {
    title: 'ID',
    dataIndex: ROW_KEY,
    ellipsis: true,
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    render: (name, record) => {
      return `WORKER_JOB_${record.jobId}.csv`;
    },
    ellipsis: true,
  },

  {
    title: 'Ngày tạo',
    dataIndex: 'createdTime',
    render: (createdTime) => UTILS.DATE_TIME.toDate(createdTime),
    ellipsis: true,
  },
  {
    title: 'Người tạo',
    dataIndex: 'userName',
  },
  {
    title: 'Thời gian hoàn thành',
    dataIndex: 'endTime',
    render: (endTime, record) => {
      if (endTime <= 0) {
        return '';
      }
      let total = endTime - record.createdTime;
      if (record.resumeTime > 0) {
        total = endTime - record.resumeTime;
      }
      const duration = moment.duration(total, 'seconds');
      const h = duration.hours();
      const m = duration.minutes();
      const s = duration.seconds();
      let text =
        `${h > 0 ? h + ' giờ ' : ''}` +
        `${m > 0 ? m + ' phút ' : ''}` +
        `${s > 0 ? s + ' giây ' : ''}`;
      return text;
    },
    ellipsis: true,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    ellipsis: true,
    render: (status, record) => {
      const progress = record.progress;
      if (progress.total > 0) {
        return `${progress.index}/${progress.total} - ${progress.percent}%`;
      }
      return UTILS.ENUM.findByValue(JOB_STATUS, status, 2);
    },
  },
  {
    title: 'File đã nhập',
    dataIndex: 'linkData',
    width: 150,
    render: (linkData) => {
      return (
        linkData && (
          <Button
            type='primary'
            style={{ padding: '0px', minWidth: '0px' }}
            href={linkData}
            icon={<DownloadOutlined />}
          />
        )
      );
    },
  },
  {
    title: 'Mã lỗi',
    dataIndex: 'fileId',
    width: 100,
    render: (fileId) => {
      return (
        fileId > 0 && (
          <Button
            type='primary'
            style={{ padding: '0px', minWidth: '0px' }}
            onClick={() => {
              const path = `${
                API_PATHS.IMPORT(container).GET.FILE
              }?id=${fileId}`;
              UTILS.FILE.download(path);
            }}
            icon={<DownloadOutlined />}
          />
        )
      );
    },
  },
  {
    title: 'Hành động',
    dataIndex: 'action',
    ellipsis: true,
    render: (action, record) => {
      return (
        <ActionButton
          key={record.status}
          status={record.status}
          container={container}
          jobId={record.jobId}
          setTableMonitor={setTableMonitor}
        />
      );
    },
  },
];

const COMMON_ELEMENTS = (
  container,
  hiddenUpload = false,
  hiddenHeaders = false
) => {
  return {
    size: 12,
    elements: [
      {
        label: 'File dữ liệu (.csv|.txt|.xlsx)',
        name: 'linkData',
        hidden: hiddenUpload,
        input: (
          <UploadFile
            accept={'.csv,.txt,.xlsx'}
            action={ENVS.UPLOAD_STATIC}
            maxCount={1}
          />
        ),
      },
      {
        label: 'Ghi chú',
        name: 'note',
        input: <TextArea />,
      },
      {
        wrapperCol: { span: 24 },
        name: 'headers',
        notBelongToForm: true,
        hidden: hiddenHeaders,
        input: <Headers container={container} />,
      },
    ],
  };
};

export { ROW_KEY, COLUMNS_LIST, COMMON_ELEMENTS, FILTER_ELEMENTS };
