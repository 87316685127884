import { EnumSelectbox } from '@cms/tpl';
import { CONTAINER } from 'common';
import ENUMS from 'enums';
import React from 'react';
import ExportCommonIndex from '../index';
import { COMMON_ELEMENTS } from '../Utils';

const elements = () => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Loại đối tác',
          name: 'type',
          input: <EnumSelectbox enums={ENUMS.BusinessPartnerType} />,
        },
        {
          label: 'Trạng thái',
          name: 'status',
          input: <EnumSelectbox enums={ENUMS.ContractStatus} />,
        },
      ],
    },
    COMMON_ELEMENTS(CONTAINER.EXPORT_BUSINESS_PARTNER),
  ];
  return [{ cols }];
};
const ExportBusinessPartnerIndex = () => {
  return (
    <ExportCommonIndex
      formElements={elements()}
      title={'Đối tác kinh doanh'}
      container={CONTAINER.EXPORT_BUSINESS_PARTNER}
    />
  );
};

export default ExportBusinessPartnerIndex;
