import { FormFilter, Portlet, PortletTable, UTILS, withAuthor } from '@cms/tpl';
import { Button, Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BusinessPartnerIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  return (
    <React.Fragment>
      <Portlet title='Tìm đối tác' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách đối tác'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='businessPartnerId'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.BUSSINESS_PARTNER.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        extendedAction={[<CreateButton navigate={navigate} />]}
      />
    </React.Fragment>
  );
};

const CreateButton = withAuthor(({ navigate }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        navigate(LINKS.toCreate(CONTAINER.BUSSINESS_PARTNER));
      }}
    >
      Tạo đối tác
    </Button>
  );
}, 'Insert');
const FILTERS = [
  {
    label: 'Tên',
    name: 'legalName',
    input: <Input placeholder='' />,
  },
  {
    label: 'Nghệ danh',
    name: 'stageName',
    input: <Input placeholder='' />,
  },
  {
    label: 'Email',
    name: 'email',
    input: <Input placeholder='' />,
  },
  {
    label: 'Số điện thoại',
    name: 'phoneNumber',
    input: <Input placeholder='' />,
  },
];
const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'businessPartnerId',
  },
  {
    title: 'Tên',
    dataIndex: 'legalName',
    render: (legalName, entry) => {
      return (
        <Link
          to={LINKS.toDetail(
            CONTAINER.BUSSINESS_PARTNER,
            entry.businessPartnerId
          )}
        >
          {legalName}
        </Link>
      );
    },
  },
  {
    title: 'Nghệ danh',
    dataIndex: 'stageName',
    render: (stageName, entry) => {
      return (
        <Link
          to={LINKS.toDetail(
            CONTAINER.BUSSINESS_PARTNER,
            entry.businessPartnerId
          )}
        >
          {stageName}
        </Link>
      );
    },
  },
  {
    title: 'Loại',
    dataIndex: 'type',
    render: (type) => {
      return UTILS.ENUM.findByValue(ENUMS.BusinessPartnerType, type, 2);
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.Status, status, 2);
    },
  },
];

export default BusinessPartnerIndex;
