function buildLinkCreate(container) {
  return `/${container}/create`;
}
function buildLinkDetail(container, id) {
  return `/${container}/detail?id=${id}`;
}
const LINKS = {
  toCreate: (container) => buildLinkCreate(container),
  toDetail: (container, id) => buildLinkDetail(container, id),
};

export default LINKS;
