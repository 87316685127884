import { TabPortlet } from '@cms/tpl';
import { CONTAINER } from 'common';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.scss';
import RevenueBody from './RevenueBody';

const BusinessPartnerRevenueIndex = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('type') || '1';

  return (
    <TabPortlet
      defaultActiveKey={tab}
      navigateName='type'
      renderTabs={[
        {
          title: 'Quảng cáo',
          body: (
            <RevenueBody
              type={'1'}
              exportContainer={CONTAINER.EXPORT_REVENUE_ADS}
            />
          ),
          key: '1',
        },
        {
          title: 'Vip',
          body: (
            <RevenueBody
              type={'2'}
              exportContainer={CONTAINER.EXPORT_REVENUE_VIP}
            />
          ),
          key: '2',
        },
        {
          title: 'Public Streaming',
          body: (
            <RevenueBody
              type={'3'}
              exportContainer={CONTAINER.EXPORT_REVENUE_PUBLIC_STREAMING}
            />
          ),
          key: '3',
        },
        {
          title: 'Ringtone',
          body: (
            <RevenueBody
              type={'4'}
              exportContainer={CONTAINER.EXPORT_REVENUE_RINGTONE}
            />
          ),
          key: '4',
        },
        {
          title: 'Orchard',
          body: (
            <RevenueBody
              type={'5'}
              exportContainer={CONTAINER.EXPORT_REVENUE_ORCHARD}
            />
          ),
          key: '5',
        },
        {
          title: 'ZMelody',
          body: (
            <RevenueBody
              type={'6'}
              exportContainer={CONTAINER.EXPORT_REVENUE_ZMELODY}
            />
          ),
          key: '6',
        },
        {
          title: 'ZStyle',
          body: (
            <RevenueBody
              type={'9'}
              exportContainer={CONTAINER.EXPORT_REVENUE_ZSTYLE}
            />
          ),
          key: '9',
        },
      ]}
    />
  );
};

export default BusinessPartnerRevenueIndex;
