import { Tag } from 'antd';
import React from 'react';

const JOB_STATUS = {
  PENDING: [1, 'Đang chờ', <Tag color={'warning'}>Đang chờ</Tag>],
  RUNNING: [2, 'Đang chạy', <Tag color={'orange'}>Đang chạy</Tag>],
  FINISHED: [3, 'Hoàn thành', <Tag color={'success'}>Hoàn thành</Tag>],
  FAILED: [4, 'Thất bại', <Tag color={'error'}>Thất bại</Tag>],
  KILLED: [5, 'Đã ngừng', <Tag color={'default'}>Đã ngừng</Tag>],
};
export default JOB_STATUS;
