import { UTILS } from '@cms/tpl';
import { Tag } from 'antd';
import React from 'react';

const BusinessPartnerType = {
  A1: [1, 'Công ty', <Tag color={UTILS.COLOR.ORANGE_RED}>Công ty</Tag>],
  A2: [2, 'Cá nhân', <Tag color={UTILS.COLOR.RED_VIOLET}>Cá nhân</Tag>],
};

export default BusinessPartnerType;
