import { ENVS } from 'common';

const BUILD_LINK = {
  buildLinkImage: (path) => {
    return `${ENVS.IMAGE_URL}/${path}`;
  },
  buildLinkImageDefault: (width, height) => {
    return `/img/text_${width}_${height}.png`;
  },
};

export default BUILD_LINK;
