import { Portlet, TabPortlet } from '@cms/tpl';
import { ICONS } from 'common';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DataMappingType from 'types/DataMappingType';
import Service from '../Service';
import DelegateMappingTab from './DelegateMappingTab';
import Detail from './Detail';
import UserManagerTab from './UserMappingTab';

const BusinessPartnerDetail = ({ created = false }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    businessPartnerId: '',
    type: 0,
    delegateId: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (!created) {
      setLoading(true);
      Service.get(id)
        .then((resp) => {
          // @ts-ignore
          setData(resp.item);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <React.Fragment>
      {created ? (
        <Portlet title='Tạo đối tác' icon={ICONS.PlusOutlined}>
          <Detail create={true} />
        </Portlet>
      ) : (
        <TabPortlet
          renderTabs={[
            {
              title: 'Thông tin đối tác',
              body: <Detail loading={loading} data={data} />,
            },
            {
              title: 'Đối tác quản lý',
              body: (
                <UserManagerTab
                  dataId={data.businessPartnerId}
                  type={DataMappingType.BUSINESS_PARTNER_MAP_USER_MANAGER}
                />
              ),
            },
            data.type == 2 && {
              title: 'Người đại diện',
              body: (
                <DelegateMappingTab
                  dataId={data.businessPartnerId}
                  delegateId={data.delegateId}
                  type={DataMappingType.BUSINESS_PARTNER_MAP_DELEGATE}
                />
              ),
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default BusinessPartnerDetail;
