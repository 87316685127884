import { TabPortlet } from '@cms/tpl';
import React from 'react';
import InputDebtIndex from './inputdebt';
import InputRevenueIndex from './inputrevenue';
import RevenueCalcIndex from './revenuecalc';

const RevenueSettingsIndex = () => {
  return (
    <TabPortlet
      destroyInactiveTabPane
      renderTabs={[
        { title: 'Nhập doanh thu', body: <InputRevenueIndex /> },
        { title: 'Nhập công nợ', body: <InputDebtIndex /> },
        { title: 'Tính doanh thu', body: <RevenueCalcIndex /> },
      ]}
    />
  );
};

export default RevenueSettingsIndex;
