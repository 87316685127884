import {
  DateRangePicker,
  EnumSelectbox,
  FormFilter,
  Portlet,
  PortletTable,
  SelectSuggest,
  UTILS,
} from '@cms/tpl';
import { Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import COLOR from 'utils/Color';

const ContractIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });

  return (
    <React.Fragment>
      <Portlet title='Tìm hợp đồng' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách hợp đồng'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='contractId'
        rowName='contractNumber'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.CONTRACT.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        buttons={[
          {
            name: 'Kinh doanh',
            style: { backgroundColor: COLOR.APPROVE },
            onAction: {
              confirm: true,
              endpoint: API_PATHS.CONTRACT.POST.SET_MUL_STATUS,
              data: { status: 1 },
            },
            icon: ICONS.Show,
            type: 'primary',
            privilege: 'Update',
          },
          {
            name: 'Ngừng kinh doanh',
            style: { backgroundColor: COLOR.HIDE },
            onAction: {
              confirm: true,
              endpoint: API_PATHS.CONTRACT.POST.SET_MUL_STATUS,
              data: { status: 2 },
            },
            icon: ICONS.Hide,
            type: 'primary',
            privilege: 'Update',
          },
          {
            name: 'Tạo hợp đồng',
            onClick: () => navigate(LINKS.toCreate(CONTAINER.CONTRACT)),
            icon: ICONS.PlusOutlined,
            type: 'primary',
            privilege: 'Insert',
          },
          {
            name: 'Import',
            onClick: () => navigate(`/${CONTAINER.IMPORT_CONTRACT}?tab=1`),
            icon: ICONS.DownloadOutlined,
            type: 'primary',
            privilege: 'Insert',
          },
          {
            name: 'Xóa',
            style: { backgroundColor: COLOR.DELETE },
            onAction: {
              confirm: true,
              endpoint: API_PATHS.CONTRACT.POST.SET_MUL_STATUS,
              data: { status: 4 },
            },
            icon: ICONS.Hide,
            type: 'primary',
            privilege: 'Delete',
          },
        ]}
      />
    </React.Fragment>
  );
};

const FILTERS = [
  {
    label: 'Số hợp đồng',
    name: 'contractNumber',
    input: <Input placeholder='' />,
  },
  {
    label: 'Đối tác',
    name: 'businessPartnerId',
    input: <SelectSuggest suggestApi={OptionService.getBusinessPartners} />,
  },
  {
    label: 'Ngày ký',
    name: 'signRange',
    input: <DateRangePicker />,
  },
  {
    label: 'Trạng thái',
    name: 'status',
    input: <EnumSelectbox enums={ENUMS.ContractStatus} />,
  },
  {
    label: 'Đã ký HĐĐT',
    name: 'eContractSigned',
    input: <EnumSelectbox enums={ENUMS.YES_NO} />,
  },
  {
    label: 'File Scan',
    name: 'hasAttachment',
    input: <EnumSelectbox enums={ENUMS.YES_NO} addOptionAll />,
  },
];
const COLUMNS = [
  {
    title: 'Số hợp đồng',
    dataIndex: 'contractNumber',
    render: (contractNumber, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.CONTRACT, entry.contractId)}>
          {contractNumber}
        </Link>
      );
    },
  },
  {
    title: 'Loại hợp đồng',
    dataIndex: 'contractType',
    render: (contractType) => {
      return UTILS.ENUM.findByValue(ENUMS.ContractType, contractType, 1);
    },
  },
  {
    title: 'Loại hình',
    dataIndex: 'businessType',
    render: (businessType) => {
      return UTILS.ENUM.findByValue(
        ENUMS.ContractBusinessType,
        businessType,
        1
      );
    },
  },
  {
    title: 'Đối tác',
    dataIndex: ['businessPartner', 'legalName'],
  },
  {
    title: 'Ngày ký',
    dataIndex: 'signTime',
    render: (value) => UTILS.DATE_TIME.toDate(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Tổng bài hát',
    dataIndex: 'mediaCount',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.ContractStatus, status, 2);
    },
  },
];

export default ContractIndex;
