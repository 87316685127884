const ENUM = {
  findByValue: (enums, value, pos) => {
    const status =
      enums[
        Object.keys(enums).find((item) => {
          return enums[item][0] == value;
        })
      ];
    if (!status) {
      return '';
    }
    if (pos) {
      return status[pos] || 'N/A';
    }
    return status;
  },
};
export default ENUM;
