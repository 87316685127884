import {
  CustomerServiceOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { FormFilter, Portlet, PortletTable, SelectSuggest } from '@cms/tpl';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Statistic,
  Switch,
} from 'antd';
import { API_PATHS, ICONS } from 'common';
import MonthPicker from 'components/monthpicker/MonthPicker';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OptionService from 'services/OptionService';
import Service from './Service';
import { buildTableColumn } from './Utils';

const RevenueBody = ({ type, exportContainer }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [total, setTotal] = useState({ play: 0, shareRevenue: 0 });
  const [data, setData] = useState({ items: [], total: 0 });
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultMonth = searchParams.get('month') || moment().format('YYYYMM');
  const columns = useMemo(() => buildTableColumn(type, showAll), [showAll]);
  useEffect(() => {
    Service.getTotal(
      form.getFieldValue('month') || defaultMonth,
      form.getFieldValue('businessPartnerId') || 0,
      type
    ).then((resp) => {
      setTotal({
        play: resp?.item?.total_play || resp?.item?.audio_listen || 0,
        shareRevenue:
          resp?.item?.total_share_revenue ||
          resp?.item?.audio_share_revenue ||
          0,
      });
    });
  }, [form.getFieldValue('month'), form.getFieldValue('businessPartnerId')]);
  return (
    <React.Fragment>
      <Row className='stats-row' gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng doanh thu (${
                (form.getFieldValue('month') &&
                  form
                    .getFieldValue('month')
                    .replace(/^(\d{4})(\d{2})$/, '$1-$2')) ||
                'Chưa chọn'
              })`}
              value={total.shareRevenue}
              valueStyle={{ color: 'rgb(34, 145, 169)' }}
              prefix={<MoneyCollectOutlined />}
              suffix='VNĐ'
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng lượt nghe/xem (${
                (form.getFieldValue('month') &&
                  form
                    .getFieldValue('month')
                    .replace(/^(\d{4})(\d{2})$/, '$1-$2')) ||
                'Chưa chọn'
              })`}
              value={total.play}
              valueStyle={{ color: 'rgb(147, 28, 143)' }}
              prefix={<CustomerServiceOutlined />}
              suffix=''
            />
          </Card>
        </Col>
      </Row>
      <Portlet title='Bộ lọc' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          initialValues={{ month: defaultMonth }}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <Divider />
      <PortletTable
        title='Dữ liệu'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        hideSelectedRow
        extendedAction={[
          <Switch
            checkedChildren='Xem rút gọn'
            unCheckedChildren='Xem tất cả'
            onChange={setShowAll}
          />,
          <Button
            type='ghost'
            icon={ICONS.UploadOutlined}
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              urlSearchParams.append('tab', '1');
              urlSearchParams.append('monthFrom', urlSearchParams.get('month'));
              urlSearchParams.append('monthTo', urlSearchParams.get('month'));
              navigate(`/${exportContainer}?${urlSearchParams.toString()}`);
            }}
          >
            Xuất dữ liệu
          </Button>,
        ]}
        source={{
          data: data,
          loadPaging: false,
          showPaging: false,
          onChangeData: setData,
          endpoint: API_PATHS.BUSINESS_PARTNER_REVENUE.GET.LIST,
          filterByQs: true,
        }}
        columns={columns}
      />
    </React.Fragment>
  );
};
const FILTERS = [
  {
    label: 'Tháng',
    name: 'month',
    input: <MonthPicker />,
  },

  {
    label: 'Đối tác sở hữu',
    name: 'businessPartnerId',
    input: (
      <SelectSuggest
        suggestApi={OptionService.getBusinessPartners}
        allowSearchEmpty
      />
    ),
  },
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractNumber',
    input: <Input />,
  },
];

export default React.memo(RevenueBody);
