import {
  DateTimePicker,
  EnumRadiobox,
  FormData,
  SelectSuggest,
  TextArea,
  UploadFile,
  usePrivilege,
} from '@cms/tpl';
import { Form, Input } from 'antd';
import { CONTAINER, ENVS, LINKS } from 'common';
import { HistoryBox, InputSwitch } from 'components';
import ENUMS from 'enums';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import Service from '../Service';

const Detail = ({ create = false, data = null, loading = false }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);
  const privilege = {
    isUpdate: usePrivilege('Update'),
  };

  return (
    <React.Fragment>
      <FormData
        name='form-detail'
        loading={loading}
        form={form}
        allowEdit={privilege.isUpdate}
        initialValues={{ status: 1 }}
        set={Service.set}
        onSetSuccess={(resp) => {
          create &&
            navigate(LINKS.toDetail(CONTAINER.DELEGATE, resp.item?.delegateId));
        }}
        rows={FORM_ITEMS(create)}
      />
    </React.Fragment>
  );
};
const FORM_ITEMS = (create) => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Email',
          name: 'email',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input disabled={!create} />,
        },
        {
          label: 'Tên',
          name: 'name',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Số chứng minh',
          name: 'identNumber',
          input: <Input />,
        },
        {
          label: 'Ảnh chứng minh',
          name: 'identPhoto',
          input: (
            <UploadFile
              withCredentials
              accept='.jpg'
              action={`${ENVS.UPLOAD_MIXUS}`}
            />
          ),
        },
        {
          label: 'Ngày cấp',
          name: 'identTime',
          input: <DateTimePicker format='DD/MM/yyyy' />,
        },
        {
          label: 'Địa chỉ',
          name: 'identAddress',
          input: <TextArea />,
        },
        {
          label: 'Số điện thoại',
          name: 'phoneNumber',
          input: <Input />,
        },
        {
          label: 'Bản cam kết HĐĐT',
          name: 'eContractFileId',
          input: (
            <UploadFile
              withCredentials
              maxCount={1}
              action={`${ENVS.UPLOAD_MIXUS}`}
            />
          ),
        },
        {
          label: 'Hợp đồng theo mẫu',
          name: 'isSampleContract',
          input: <InputSwitch />,
        },
        {
          label: 'Trạng thái',
          name: 'status',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <EnumRadiobox enums={ENUMS.Status} />,
        },
      ],
    },
    {
      size: 12,
      elements: [
        {
          label: 'Vị trí',
          name: 'position',
          input: <Input />,
        },
        {
          label: 'Ngân hàng - Tên',
          name: 'bankId',
          input: (
            <SelectSuggest
              allowSearchEmpty
              suggestApi={OptionService.getBanks}
            />
          ),
        },
        {
          label: 'Ngân hàng - Tên TK',
          name: 'bankAccountName',
          input: <Input />,
        },
        {
          label: 'Ngân hàng - Số TK',
          name: 'bankAccountNumber',
          input: <Input />,
        },
        {
          label: 'Ngân hàng - Chi nhánh',
          name: 'bankBranch',
          input: <Input />,
        },
        {
          label: ' ',
          colon: false,
          name: '_historyBox',
          notBeLongToForm: true,
          input: <HistoryBox />,
        },
      ],
    },
  ];

  return [{ cols }];
};

export default Detail;
