import { DownloadOutlined } from '@ant-design/icons';
import { Card, Row, Spin, Tag, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import UTILS from 'utils';
import * as XLSX from 'xlsx';
import Service from '../../../Services';
import './Headers.scss';

const Headers = ({ type, title, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Service.getHeaders(type)
      .then((data) => {
        setData(data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDownloadTemplate = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet([], {
      header: data,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${title}-template.xlsx`);
  }, [data]);

  return (
    <div key='headers' className='headers'>
      <Spin indicator={UTILS.CLASS.LOADER} spinning={loading}>
        <Card
          className='headers-card'
          title='Các cột hợp lệ (Bắt buộc theo thứ tự và không chứa tên cột trong file)'
          extra={
            <Tooltip title='Tải template'>
              <DownloadOutlined
                type='button'
                onClick={handleDownloadTemplate}
              />
            </Tooltip>
          }
        >
          <Row>
            {data.map((entry, i) => {
              return (
                <Tag key={i} color='orange'>
                  {entry}
                </Tag>
              );
            })}
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default Headers;
