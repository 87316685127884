import {
  FormFilter,
  Portlet,
  PortletTable,
  ThumbImage,
  UTILS,
  withAuthor,
} from '@cms/tpl';
import { Button, Form, Input } from 'antd';
import { API_PATHS, ICONS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import Detail from './Detail';

const ArtistIndex = () => {
  const [form] = Form.useForm();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  const [detail, setDetail] = useState({ open: false, id: 0, name: '' });
  return (
    <React.Fragment>
      <Portlet title='Tìm nghệ sĩ' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách nghệ sĩ'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='artistId'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.ARTIST.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS(setDetail)}
        // extendedAction={[<CreateButton setDetail={setDetail} />]}
      />
      <Detail
        detail={detail}
        setDetail={setDetail}
        setTableMonitor={setTableMonitor}
      />
    </React.Fragment>
  );
};

const CreateButton = withAuthor(({ setDetail }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        setDetail({ open: true, id: 0 });
      }}
    >
      Tạo nghệ sĩ
    </Button>
  );
}, 'Insert');
const FILTERS = [
  {
    label: 'Tên nghệ sĩ',
    name: 'name',
    input: <Input placeholder='' />,
  },
];
const COLUMNS = (setDetail) => [
  {
    title: 'ID',
    dataIndex: 'artistId',
  },
  {
    title: 'Tên nghệ sĩ',
    dataIndex: 'name',
    render: (name, entry) => {
      return (
        <a
          onClick={() => {
            setDetail({ open: true, id: entry.artistId, name: entry.name });
          }}
        >
          {name}
        </a>
      );
    },
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    render: (avatar, entry) => {
      return <ThumbImage path={avatar} />;
    },
  },
  {
    title: 'Tên thật',
    dataIndex: 'realName',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.Status, status, 2);
    },
  },
];

export default ArtistIndex;
