import { ModalFormData, UploadFile } from '@cms/tpl';
import { Form, Input, Spin } from 'antd';
import { ENVS } from 'common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Service from '../../Services';
import Headers from './header/Headers';
import Section from './section/Section';

const Settings = ({ month }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const settings = useRef(new Map());

  const [data, setData] = useState({ type: 0, title: '' });

  const handleShowModal = (title, type) => {
    setData({ title: title, type: type });
    setVisible(true);
  };
  const handleSetSettingJob = (jobId, running) => {
    setLoading(true);
    Service.setSettingJob(jobId, running).finally(() => {
      fetchData().finally(() => {
        setLoading(false);
      });
    });
  };
  const handleSetPublic = (month, type, isPublic) => {
    setLoading(true);
    Service.setPublic(month, type, isPublic).finally(() => {
      fetchData().finally(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldValue('type', data.type);
    form.setFieldValue('month', month);
  }, [data.type, month]);

  const fetchData = () =>
    Service.getSettings(month)
      .then((res) => {
        let value = new Map();
        for (let i = 0; i < res.items.length; i++) {
          let data = res.items[i];
          value.set(data.type, data);
        }
        settings.current = value;
      })
      .finally(() => {
        setReload((re) => !re);
      });

  useEffect(() => {
    setLoading(true);
    fetchData().finally(() => {
      setLoading(false);
    });
    let fetching = false;
    let interval = setInterval(() => {
      // @ts-ignore
      if (document.visibilityState == 'visible' && !fetching) {
        fetching = true;
        fetchData().finally(() => {
          fetching = false;
        });
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [month]);
  const sections = useMemo(() => {
    return (
      <React.Fragment>
        <Section
          title={'Doanh thu quảng cáo'}
          type={1}
          setting={settings.current.get(1)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/zmp3-ads-import-template.xlsx'
        />
        <Section
          title={'Doanh thu VIP'}
          type={2}
          setting={settings.current.get(2)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/zmp3-vip-import-template.xlsx'
        />
        <Section
          title={'Doanh thu Public Streaming'}
          type={3}
          setting={settings.current.get(3)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/ps-import-template.xlsx'
        />
        <Section
          title={'Doanh thu Ringtone'}
          type={4}
          setting={settings.current.get(4)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/ringtone-import-template.xlsx'
        />
        <Section
          title={'Doanh thu Orchard'}
          type={5}
          setting={settings.current.get(5)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/orchard-import-template.xlsx'
        />
        <Section
          title={'Doanh thu zMelody'}
          type={6}
          setting={settings.current.get(6)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/zmelody-import-template.xlsx'
        />
        <Section
          title={'Doanh thu zStyle'}
          type={9}
          setting={settings.current.get(9)}
          onInputModal={handleShowModal}
          onSetSettingJob={handleSetSettingJob}
          onSetPublic={handleSetPublic}
          templateFile='https://zmp3-static.zmdcdn.me/data/mixus/templates/zstyle-import-template.xlsx'
        />
      </React.Fragment>
    );
  }, [reload]);

  const importForm = useMemo(() => {
    return (
      <ModalFormData
        name='import-data'
        title={`Nhập dữ liệu: ${data.title}`}
        key='import-data'
        visible={visible}
        destroyOnClose
        onVisible={setVisible}
        okText='Xác nhận'
        onSubmit={(values) => {
          return Service.setSetting(values).finally(() => {
            setLoading(true);
            fetchData().finally(() => {
              setLoading(false);
            });
          });
        }}
        form={form}
        items={[
          {
            label: 'Tháng',
            name: 'month',
            input: <Input disabled />,
          },
          {
            name: 'type',
            hidden: true,
            input: <Input disabled />,
          },
          {
            label: 'File dữ liệu (.csv|.txt|.xlsx)',
            name: 'files',
            input: (
              <UploadFile
                accept={'.csv,.txt,.xlsx'}
                action={ENVS.UPLOAD_STATIC}
                maxCount={1}
              />
            ),
          },
          {
            wrapperCol: { span: 24 },
            name: 'headers',
            notBelongToForm: true,
            input: <Headers title={data.title} type={data.type} />,
          },
        ]}
      />
    );
  }, [visible]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>{sections}</Spin>
      {importForm}
    </React.Fragment>
  );
};

export default Settings;
