import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
import UTILS from 'utils';

const Service = {
  setSetting: (entity) => {
    let data = new URLSearchParams();
    data.append(
      'entity',
      JSON.stringify(UTILS.FORMAT.removeJsonValueEmpty(entity))
    );
    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.SET_SETTING,
      data
    );
  },
  setPublic: (month, type, isPublic) => {
    let data = new URLSearchParams({ month, type, isPublic });
    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.SET_PUBLIC,
      data
    );
  },
  setSettingJob: (jobId, running) => {
    let data = new URLSearchParams({ jobId, running });

    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.SET_SETTING_JOB,
      data
    );
  },
  getSettings: (month) =>
    HELPER.API.executeGet(
      `${API_PATHS.REVENUE_SETTINGS.GET.SETTINGS}?month=${month}`
    ),
  getHeaders: (type) =>
    HELPER.API.executeGet(
      `${API_PATHS.REVENUE_SETTINGS.GET.HEADERS}?type=${type}`
    ),
  kill: (jobId) => {
    let data = new URLSearchParams();
    data.append('jobId', jobId);
    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.KILL,
      data
    );
  },
  resume: (jobId) => {
    let data = new URLSearchParams();
    data.append('jobId', jobId);
    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.RESUME,
      data
    );
  },
  setJob: (entity) => {
    let data = new URLSearchParams();
    data.append(
      'entity',
      JSON.stringify(UTILS.FORMAT.removeJsonValueEmpty(entity))
    );
    return HELPER.API.executePostForm(
      API_PATHS.REVENUE_SETTINGS.POST.SET_JOB,
      data
    );
  },

  getJob: (type) =>
    HELPER.API.executeGet(`${API_PATHS.REVENUE_SETTINGS.GET.JOB}?type=${type}`),
};

export default Service;
