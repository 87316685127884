import { CONTAINER } from 'common';
import React from 'react';
import ImportCommonIndex from '../index';
import { COMMON_ELEMENTS } from '../Utils';

const elements = () => {
  const cols = [
    COMMON_ELEMENTS(CONTAINER.IMPORT_CONTRACT),
    {
      size: 12,
      elements: [],
    },
  ];
  return [{ cols }];
};
const ImportContractIndex = () => {
  return (
    <ImportCommonIndex
      formElements={elements()}
      title={'Hợp đồng'}
      container={CONTAINER.IMPORT_CONTRACT}
    />
  );
};

export default ImportContractIndex;
