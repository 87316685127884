import { FormData, HELPER, Portlet } from '@cms/tpl';
import { Divider, Form, Table } from 'antd';
import { ICONS } from 'common';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import COLOR from 'utils/Color';
import ExportService from './Services';

const CreateJob = ({ container, formElements, hidePreview }) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  form.setFieldsValue(searchParams);

  const handlePreview = useCallback(() => {
    setLoading(true);
    setDataSource([]);
    setColumns([]);
    setTotal(0);
    let timeBenchmark = moment().valueOf();
    ExportService(container)
      .getDataPreview(form.getFieldsValue())
      .then((data) => {
        timeBenchmark = Math.round((moment().valueOf() - timeBenchmark) / 1000);
        if (timeBenchmark >= 3) {
          HELPER.ALERT.notifyWarn(
            'Cảnh báo',
            'Bộ lọc này mất nhiều thời gian để xuất dữ liệu!'
          );
        }
        let source = data?.item?.items.map((d, i) => {
          return { ...d, key: i };
        });
        let columns =
          data?.item?.items.length > 0 &&
          Object.keys(data?.item?.items[0]).map((d, i) => {
            return {
              title: d,
              dataIndex: d,
              key: d,
            };
          });
        setDataSource(source);
        setColumns(columns);
        setTotal(data?.item?.total || 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const formData = useMemo(
    () => (
      <FormData
        name='detail'
        submitText={'Xuất dữ liệu'}
        onConfirmText={() => {
          return 'Bạn có chắc muốn xuất dữ liệu!';
        }}
        form={form}
        countdown={0}
        rows={formElements || []}
        set={(id, entity) => {
          return ExportService(container)
            .add(id, entity)
            .then(() => {
              setDataSource([]);
              setColumns([]);
              setTotal(0);
              form.resetFields();
            });
        }}
        extendActions={[
          !hidePreview && {
            text: 'Xem trước dữ liệu',
            icon: ICONS.DatabaseOutlined,
            style: {
              background: COLOR.REFRESH,
              borderColor: COLOR.REFRESH,
            },
            type: 'primary',
            action: handlePreview,
          },
        ]}
      />
    ),
    []
  );
  return (
    <React.Fragment>
      {formData}
      {!hidePreview && (
        <React.Fragment>
          <Divider />
          <Portlet title={`Tổng số: ${total}`}>
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Portlet>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(CreateJob);
