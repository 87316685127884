import {
  DateTimePicker,
  EnumRadiobox,
  EnumSelectbox,
  FormData,
  SelectSuggest,
  TextArea,
  UploadFile,
  usePrivilege,
} from '@cms/tpl';
import { Form, Input } from 'antd';
import { CONTAINER, ENVS, LINKS } from 'common';
import { HistoryBox, InputSwitch } from 'components';
import ENUMS from 'enums';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import Service from '../Service';

const Detail = ({ create = false, data = null, loading = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);
  const privilege = {
    isUpdate: usePrivilege('Update'),
  };
  return (
    <React.Fragment>
      <FormData
        name='form-detail'
        loading={loading}
        form={form}
        allowEdit={privilege.isUpdate}
        initialValues={{ status: 1 }}
        set={Service.set}
        onSetSuccess={(resp) => {
          create &&
            navigate(
              LINKS.toDetail(
                CONTAINER.BUSSINESS_PARTNER,
                resp.item?.businessPartnerId
              )
            );
        }}
        rows={FORM_ITEMS(create)}
      />
    </React.Fragment>
  );
};
const FORM_ITEMS = (create) => {
  const cols = [
    {
      size: 12,
      elements: [
        !create && {
          label: 'Mã đối tác',
          name: 'encodeId',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input disabled={!create} />,
        },
        {
          label: 'Loại đối tác',
          name: 'type',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: (
            <EnumSelectbox
              enums={ENUMS.BusinessPartnerType}
              disabled={!create}
            />
          ),
        },
        {
          label: 'Tên',
          name: 'legalName',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Email',
          name: 'email',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Tên người đại diện',
          name: 'repName',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          requiredByItems: [{ key: 'type', value: '1' }],
          input: <Input />,
        },
        {
          label: 'Chức vụ',
          name: 'position',
          requiredByItems: [{ key: 'type', value: '1' }],
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Nghệ danh',
          name: 'stageName',
          requiredByItems: [{ key: 'type', value: '2' }],
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Mã số thuế',
          name: 'taxCode',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Số điện thoại',
          name: 'phoneNumber',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },

        {
          label: 'Địa chỉ liên hệ',
          name: 'contactAddress',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <TextArea />,
        },
        {
          label: 'Hợp đồng theo mẫu',
          name: 'isSampleContract',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <InputSwitch />,
        },
        {
          label: 'Bản cam kết HĐĐT',
          name: 'eContractFileId',
          input: (
            <UploadFile
              withCredentials
              maxCount={1}
              action={`${ENVS.UPLOAD_MIXUS}`}
            />
          ),
        },
        {
          label: 'Trạng thái',
          name: 'status',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <EnumRadiobox enums={ENUMS.Status} />,
        },
      ],
    },
    {
      size: 12,
      elements: [
        {
          label: 'File đính kèm (CMND/CCCD/GPKD)',
          name: 'identPhotoFiles',
          input: (
            <UploadFile
              withCredentials
              maxCount={1}
              action={`${ENVS.UPLOAD_MIXUS}`}
            />
          ),
        },
        {
          label: 'CMND/CCCD - Số',
          name: 'identNumber',
          requiredByItems: [{ key: 'type', value: '2' }],
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'CMND/CCCD - Nơi cấp',
          requiredByItems: [{ key: 'type', value: '2' }],
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          name: 'identAddress',
          input: <Input />,
        },
        {
          label: 'CMND/CCCD - Ngày cấp',
          requiredByItems: [{ key: 'type', value: '2' }],
          name: 'identDate',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <DateTimePicker format='DD/MM/YYYY' />,
        },
        {
          label: 'Ngân hàng',
          name: 'bankId',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: (
            <SelectSuggest
              allowSearchEmpty
              suggestApi={OptionService.getBanks}
            />
          ),
        },
        {
          label: 'Ngân hàng - Tên TK',
          name: 'bankAccountName',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Ngân hàng - Số TK',
          name: 'bankAccountNumber',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Ngân hàng - Chi nhánh',
          name: 'bankBranch',
          rules: [{ required: true, message: 'Vui lòng nhập!' }],
          input: <Input />,
        },
        {
          label: 'Số Fax',
          name: 'fax',
          input: <Input />,
        },
        {
          label: ' ',
          colon: false,
          name: '_historyBox',
          notBeLongToForm: true,
          input: <HistoryBox />,
        },
      ],
    },
  ];

  return [{ cols }];
};

export default Detail;
