import { SelectSuggest } from '@cms/tpl';
import { Input } from 'antd';
import { CONTAINER } from 'common';
import React from 'react';
import OptionService from 'services/OptionService';
import ExportCommonIndex from '../index';
import { COMMON_ELEMENTS } from '../Utils';
const container = CONTAINER.EXPORT_DEBT_MG_ZINGCHART;
const elements = () => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Đối tác',
          name: 'businessPartnerId',
          input: (
            <SelectSuggest suggestApi={OptionService.getBusinessPartners} />
          ),
        },

        {
          label: 'Số hợp đồng',
          name: 'contractNumber',
          input: <Input />,
        },
      ],
    },
    COMMON_ELEMENTS(container, true),
  ];
  return [{ cols }];
};
const ExportDebtMGZingchartIndex = () => {
  return (
    <ExportCommonIndex
      formElements={elements()}
      title={'Hợp đồng MG Zingchart'}
      container={container}
    />
  );
};

export default ExportDebtMGZingchartIndex;
