import { FormData } from '@cms/tpl';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import ImportService from './Services';

const CreateJob = ({ container, formElements, initialValues }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <React.Fragment>
      <FormData
        name='detail'
        submitText={'Nhập dữ liệu'}
        onConfirmText={() => {
          return 'Bạn có chắc muốn nhập dữ liệu!';
        }}
        form={form}
        countdown={0}
        rows={formElements || []}
        set={(id, entity) => {
          return ImportService(container)
            .add(id, entity)
            .then(() => {
              form.resetFields();
            });
        }}
      />
    </React.Fragment>
  );
};

export default CreateJob;
