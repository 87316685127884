import moment from 'moment';

const DATE_TIME = {
  toDate: (value, format) => {
    if (!value || value <= 0) {
      return '';
    }
    const inp = value.toString().length > 10 ? value : value * 1000;
    return moment(inp).format(format ? format : 'DD/MM/YYYY HH:mm');
  },
};

export default DATE_TIME;
