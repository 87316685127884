const MediaStatus = {
  UNKNOW: [0, 'Không xác định', 'gray'],
  SHOW: [1, 'Hiển thị', 'blue'],
  HIDE: [2, 'Ẩn', 'gray'],
  WAITING_APPROVE: [3, 'Chờ duyệt', 'yellow'],
  DISAPPROVE: [4, 'Không duyệt', 'red'],
  DELETED: [5, 'Xóa', 'red'],
  LOCK: [6, 'Khóa', 'red'],
  CONVERTING: [7, 'Đang Convert', 'green'],
  CONVERT_ERROR: [8, 'Convert lỗi', 'red'],
  WAITING_COPYRIGHT: [9, 'Chờ bản quyền', 'red'],
  BANNED_CIRCULATE: [10, 'Cấm lưu hành', 'red'],
  EXPIRED: [11, 'Hết hạn', 'yellow'],
};
export default MediaStatus;
