import { PortletTable, UTILS } from '@cms/tpl';
import { Button, Form } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AddendumTab = ({ contractId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  return (
    <React.Fragment>
      <PortletTable
        title='Danh sách phụ lục'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='addendumId'
        hideSelectedRow
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.CONTRACT.GET.PAG_ADDENDUM,
          loadPaging: false,
          filterByQs: true,
          showPaging: false,
          filterParams: { contractId: contractId },
        }}
        columns={COLUMNS}
        extendedAction={[
          <CreateButton navigate={navigate} contractId={contractId} />,
        ]}
      />
    </React.Fragment>
  );
};
const CreateButton = ({ navigate, contractId }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        navigate(
          `${LINKS.toCreate(CONTAINER.ADDENDUM)}?contractId=${contractId}`
        );
      }}
    >
      Tạo phụ lục
    </Button>
  );
};

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'addendumId',
  },
  {
    title: 'Số hợp đồng',
    dataIndex: 'addendumNumber',
    render: (addendumNumber, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.ADDENDUM, entry.addendumId)}>
          {addendumNumber}
        </Link>
      );
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.AddendumStatus, status, 2);
    },
  },
];

export default AddendumTab;
