import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
const API = API_PATHS.OPTION;
const buildApi = (path, keyword, page) => {
  return `${path}?keyword=${keyword}&page=${page}`;
};
const OptionService = {
  getBanks: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.BANKS, keyword, page)),
  getContracts: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.CONTRACTS, keyword, page)),
  getArtists: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.ARTISTS, keyword, page)),
  getBusinessPartners: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.BUSINESS_PARTNERS, keyword, page)),
  getAccounts: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.ACCOUNTS, keyword, page)),
  getMediaMap: (keyword, page) =>
    HELPER.API.executeGet(buildApi(API.GET.MEDIA_MAP, keyword, page)),
  getSharingRatio: () => HELPER.API.executeGet(API.GET.SHARING_RATIO),
  getOldSharingRatio: () => HELPER.API.executeGet(API.GET.OLD_SHARING_RATIO),
};

export default OptionService;
