import FORMAT from 'utils/Format';

const columnsCommon = [
  { title: 'Mixus ID', dataIndex: 'mediaId' },
  { title: 'Tiêu đề', dataIndex: ['media', 'title'] },
  { title: 'Ca sĩ', dataIndex: 'artistName' },
  { title: 'Đối tác', dataIndex: ['businessPartner', 'legalName'] },
  { title: 'Hợp đồng', dataIndex: 'contractNumber' },
  { title: 'Phụ lục', dataIndex: 'addendumNumber' },
];

const columnsAds = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Loại độc quyền', dataIndex: 'monopolyRatio' },
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};
const columnsVip = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};
const columnsPublicStreaming = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};
const columnsRingtone = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};
const columnsOrchard = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};

const columnsZMelody = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};

const columnsZStyle = (showAll) => {
  return showAll
    ? [
        ...columnsCommon,
        { title: 'Tỷ lệ chia sẻ', dataIndex: 'ratioShare' },
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu',
          dataIndex: 'totalRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ]
    : [
        ...columnsCommon,
        {
          title: 'Lượt play',
          dataIndex: 'totalPlay',
          render: (value) => FORMAT.numberWithCommas(value),
        },
        {
          title: 'Doanh thu chia sẻ',
          dataIndex: 'totalShareRevenue',
          render: (value) => FORMAT.numberWithCommas(value),
        },
      ];
};

const buildTableColumn = (type, showAll) => {
  switch (type) {
    case '1':
      return columnsAds(showAll);
    case '2':
      return columnsVip(showAll);
    case '3':
      return columnsPublicStreaming(showAll);
    case '4':
      return columnsRingtone(showAll);
    case '5':
      return columnsOrchard(showAll);
    case '6':
      return columnsZMelody(showAll);
    case '9':
      return columnsZStyle(showAll);
  }
  return [];
};
export { buildTableColumn };
