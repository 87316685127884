import Icon, {
  BarsOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  FundViewOutlined,
  HistoryOutlined,
  InboxOutlined,
  LikeOutlined,
  MinusCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  RetweetOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  StopTwoTone,
  SwapOutlined,
  UnlockOutlined,
  UploadOutlined,
  VideoCameraTwoTone,
} from '@ant-design/icons';
import React from 'react';
import { ReactComponent as Album } from '../assets/icons/album.svg';
import { ReactComponent as Analytics } from '../assets/icons/analytics.svg';
import { ReactComponent as Android } from '../assets/icons/android.svg';
import { ReactComponent as Apple } from '../assets/icons/apple.svg';
import { ReactComponent as AppSettings } from '../assets/icons/app_settings.svg';
import { ReactComponent as Artist } from '../assets/icons/artist.svg';
import { ReactComponent as Audio } from '../assets/icons/audio.svg';
import { ReactComponent as Chat } from '../assets/icons/chat.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Collections } from '../assets/icons/collections.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';
import { ReactComponent as Done } from '../assets/icons/done.svg';
import { ReactComponent as EditNotification } from '../assets/icons/edit_notifications.svg';
import { ReactComponent as Event } from '../assets/icons/event.svg';
import { ReactComponent as Events } from '../assets/icons/events.svg';
import { ReactComponent as Feed } from '../assets/icons/feed.svg';
import { ReactComponent as Genre } from '../assets/icons/genre.svg';
import { ReactComponent as Hide } from '../assets/icons/hide.svg';
import { ReactComponent as ImportExport } from '../assets/icons/import_export.svg';
import { ReactComponent as LibraryMusic } from '../assets/icons/library_music.svg';
import { ReactComponent as LineChart } from '../assets/icons/line_chart.svg';
import { ReactComponent as People } from '../assets/icons/people.svg';
import { ReactComponent as Public } from '../assets/icons/public.svg';
import { ReactComponent as Refresh } from '../assets/icons/refresh.svg';
import { ReactComponent as Rule } from '../assets/icons/rule.svg';
import { ReactComponent as Show } from '../assets/icons/show.svg';
import { ReactComponent as Stop } from '../assets/icons/stop.svg';
import { ReactComponent as Sync } from '../assets/icons/sync.svg';
import { ReactComponent as Tag } from '../assets/icons/tag.svg';
import { ReactComponent as Theme } from '../assets/icons/theme.svg';
import { ReactComponent as Topic } from '../assets/icons/topic.svg';
import { ReactComponent as Upload } from '../assets/icons/upload.svg';
import { ReactComponent as Video } from '../assets/icons/video.svg';
import { ReactComponent as VolumeOff } from '../assets/icons/volume_off.svg';
import { ReactComponent as VolumeOn } from '../assets/icons/volume_up.svg';
import { ReactComponent as Web } from '../assets/icons/web.svg';

const ICONS = {
  DownloadOutlined: <DownloadOutlined />,
  CopyOutlined: <CopyOutlined />,
  VideoCameraTwoTone: <VideoCameraTwoTone color='red' />,
  SwapOutlined: <SwapOutlined />,
  PlayCircleOutlined: <PlayCircleOutlined />,
  SettingOutlined: <SettingOutlined />,
  UnlockOutlined: <UnlockOutlined />,
  LikeOutlined: <LikeOutlined />,
  EnvironmentOutlined: <EnvironmentOutlined />,
  CalendarOutlined: <CalendarOutlined />,
  ExclamationCircleOutlined: <ExclamationCircleOutlined />,
  DownOutlined: <DownOutlined />,
  CloseOutlined: <CloseOutlined />,
  DatabaseOutlined: <DatabaseOutlined />,
  UploadOutlined: <UploadOutlined />,
  CheckOutlined: <CheckOutlined />,
  HistoryOutlined: <HistoryOutlined />,
  InboxOutlined: <InboxOutlined />,
  BarsOutlined: <BarsOutlined />,
  MinusCircleOutlined: <MinusCircleOutlined />,
  CheckCircleOutlined: <CheckCircleOutlined />,
  DeleteOutlined: <DeleteOutlined />,
  StarOutlined: <StarOutlined />,
  PlusOutlined: <PlusOutlined />,
  SaveOutlined: <SaveOutlined />,
  EditOutlined: <EditOutlined />,
  SearchOutlined: <SearchOutlined />,
  RetweetOutlined: <RetweetOutlined />,
  FundViewOutlined: <FundViewOutlined />,
  Album: <Icon component={Album} />,
  Rule: <Icon component={Rule} />,
  Chat: <Icon component={Chat} />,
  Audio: <Icon component={Audio} />,
  Public: <Icon component={Public} />,
  Show: <Icon component={Show} />,
  Hide: <Icon component={Hide} />,
  Video: <Icon component={Video} />,
  Genre: <Icon component={Genre} />,
  Collections: <Icon component={Collections} />,
  Topic: <Icon component={Topic} />,
  People: <Icon component={People} />,
  Artist: <Icon component={Artist} />,
  LibraryMusic: <Icon component={LibraryMusic} />,
  Theme: <Icon component={Theme} />,
  Delete: <Icon component={Delete} style={{ fontSize: '24px' }} />,
  Upload: <Icon component={Upload} style={{ fontSize: '24px' }} />,
  Done: <Icon component={Done} style={{ fontSize: '24px', color: 'green' }} />,
  Close: <Icon component={Close} style={{ fontSize: '24px', color: 'red' }} />,
  Feed: <Icon component={Feed} />,
  EditNotification: <Icon component={EditNotification} />,
  Events: <Icon component={Events} />,
  Event: <Icon component={Event} />,
  Tag: <Icon component={Tag} />,
  AppSettings: <Icon component={AppSettings} />,
  StopTwoTone: <StopTwoTone twoToneColor='red' />,
  Android: <Icon component={Android} />,
  Web: <Icon component={Web} />,
  Apple: <Icon component={Apple} />,
  ImportExport: <Icon component={ImportExport} />,
  Refresh: <Icon component={Refresh} />,
  Stop: <Icon component={Stop} />,
  Analytics: <Icon component={Analytics} />,
  Sync: <Icon component={Sync} />,
  LineChart: <Icon component={LineChart} />,
  VolumeOn: <Icon component={VolumeOn} />,
  VolumeOff: <Icon component={VolumeOff} />,
};

export default ICONS;
