import BUILD_LINK from './BuildLink';
import CLASS from './Class';
import COLOR from './Color';
import DATE_TIME from './DateTime';
import ENUM from './Enum';
import FILE from './File';
import FORMAT from './Format';
import OPTION from './Option';

const UTILS = {
  DATE_TIME,
  BUILD_LINK,
  OPTION,
  FORMAT,
  COLOR,
  ENUM,
  CLASS,
  FILE,
};

export default UTILS;
