import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
const API = API_PATHS.BUSINESS_PARTNER_REVENUE;
const Service = {
  getTotal: (month, businessPartnerId, type) =>
    HELPER.API.executeGet(
      `${API.GET.TOTAL}?month=${month}&businessPartnerId=${businessPartnerId}&type=${type}`
    ),
};
export default Service;
