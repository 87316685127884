import { FormFilter, Portlet, PortletTable, UTILS, withAuthor } from '@cms/tpl';
import { Button, Form, Input } from 'antd';
import { API_PATHS, CONTAINER, ICONS, LINKS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DelegateIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  return (
    <React.Fragment>
      <Portlet title='Tìm người đại diện' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách người đại diện'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        rowKey='delegateId'
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.DELEGATE.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        extendedAction={[<CreateButton navigate={navigate} />]}
      />
    </React.Fragment>
  );
};
const CreateButton = withAuthor(({ navigate }) => {
  return (
    <Button
      icon={ICONS.PlusOutlined}
      type='primary'
      onClick={() => {
        navigate(LINKS.toCreate(CONTAINER.DELEGATE));
      }}
    >
      Tạo người đại diện
    </Button>
  );
}, 'Insert');
const FILTERS = [
  {
    label: 'Email',
    name: 'email',
    input: <Input placeholder='' />,
  },
  {
    label: 'Tên',
    name: 'name',
    input: <Input placeholder='' />,
  },
];
const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'delegateId',
  },
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (email, entry) => {
      return (
        <Link to={LINKS.toDetail(CONTAINER.DELEGATE, entry.delegateId)}>
          {email}
        </Link>
      );
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.Status, status, 2);
    },
  },
];

export default DelegateIndex;
