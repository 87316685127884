import { UTILS } from '@cms/tpl';
import { Tag } from 'antd';
import React from 'react';
const AddendumStatus = {
  A1: [
    1,
    'Đang kinh doanh',
    <Tag color={UTILS.COLOR.GREEN}>Đang kinh doanh</Tag>,
  ],
  A2: [
    2,
    'Ngừng kinh doanh',
    <Tag color={UTILS.COLOR.YELLOW_ORANGE}>Ngừng kinh doanh</Tag>,
  ],
  A3: [3, 'Hết hạn', <Tag color={UTILS.COLOR.RED_VIOLET}>Hết hạn</Tag>],
  A4: [4, 'Xóa', <Tag color={UTILS.COLOR.RED}>Xóa</Tag>],
};
export default AddendumStatus;
