import { TabPortlet } from '@cms/tpl';
import { ICONS } from 'common';
import React from 'react';
import CreateJob from './CreateJob';
import ListJob from './ListJob';

const ImportCommonIndex = ({
  title,
  container,
  formElements,
  initialValues = {},
}) => {
  return (
    <TabPortlet
      title={'Nhập dữ liệu: ' + title}
      icon={ICONS.BarsOutlined}
      destroyInactiveTabPane={true}
      renderTabs={[
        {
          title: 'Danh sách File',
          body: <ListJob container={container} />,
        },
        {
          title: 'Tạo yêu cầu mới',
          body: (
            <CreateJob
              container={container}
              formElements={formElements}
              initialValues={initialValues}
            />
          ),
        },
      ]}
    />
  );
};

export default ImportCommonIndex;
