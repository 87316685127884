import {
  DownloadOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  NotificationOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Progress,
  Row,
  Tag,
  Tooltip,
} from 'antd';
import { API_PATHS } from 'common';
import React from 'react';
import UTILS from 'utils';
import JOB_STATUS from './JobStatus';
const Section = ({
  title,
  type,
  onInputModal,
  setting,
  onSetSettingJob,
  onSetPublic,
  templateFile,
}) => {
  const {
    jobId,
    isPublic,
    month,
    jobStatus,
    jobFileImport,
    jobFileError,
    jobProgress,
  } = setting || {
    jobStatus: null,
    jobFileImport: null,
    jobFileError: null,
    jobProgress: null,
    jobId: 0,
    isPublic: false,
    month: 0,
  };
  const playEnable = !(
    jobStatus == JOB_STATUS.UNREADY[0] ||
    jobStatus == JOB_STATUS.RUNNING[0] ||
    jobStatus == JOB_STATUS.KILLED[0]
  );
  const publicEnable = !(jobStatus == JOB_STATUS.FINISHED[0]);

  const running = jobStatus == JOB_STATUS.RUNNING[0];

  return (
    <Card
      title={<h4>{title}</h4>}
      style={{ width: '490px' }}
      bordered
      actions={[
        <Tooltip title='Nhập dữ liệu' placement={'bottom'}>
          <Button
            onClick={() => onInputModal(title, type)}
            type='text'
            icon={<EditOutlined />}
          />
        </Tooltip>,
        <Tooltip
          title={running ? 'Ngừng tính' : 'Thực hiện tính'}
          placement={'bottom'}
        >
          <Popconfirm
            title={
              running
                ? `Xác nhận ngừng tính: ${title}`
                : `Xác nhận thực hiện tính: ${title}`
            }
            okText='Xác nhận'
            cancelText='Hủy'
            onConfirm={() => onSetSettingJob(jobId, running)}
            disabled={playEnable}
          >
            <Button
              disabled={playEnable}
              type='text'
              icon={running ? <StopOutlined /> : <PlayCircleOutlined />}
            />
          </Popconfirm>
        </Tooltip>,
        <Tooltip
          title={!isPublic ? 'Thực hiện public' : 'Ngừng public'}
          placement={'bottom'}
        >
          <Popconfirm
            title={
              !isPublic
                ? `Xác nhận public: ${title}`
                : `Xác nhận ngừng public: ${title}`
            }
            okText='Xác nhận'
            cancelText='Hủy'
            onConfirm={() => onSetPublic(month, type, !isPublic)}
            disabled={publicEnable}
          >
            <Button
              disabled={publicEnable}
              type='text'
              icon={
                !isPublic ? <NotificationOutlined /> : <EyeInvisibleOutlined />
              }
            />
          </Popconfirm>
        </Tooltip>,
      ]}
    >
      <Row>
        <Col span={14}>
          <ul>
            <li>
              Trạng thái:{' '}
              {UTILS.ENUM.findByValue(JOB_STATUS, jobStatus, 2) || (
                <Tag color='default'>Chưa tạo nội dung</Tag>
              )}
            </li>
            <li>
              Dữ liệu tính doanh thu:{' '}
              <Button
                disabled={!jobFileImport}
                onClick={() => {
                  UTILS.FILE.download(jobFileImport);
                }}
                type='text'
                icon={<DownloadOutlined />}
              ></Button>
            </li>
            <li>
              Nội dung lỗi:{' '}
              <Button
                disabled={!jobFileError || jobFileError <= 0}
                onClick={() => {
                  UTILS.FILE.download(
                    `${API_PATHS.REVENUE_SETTINGS.GET.FILE}?id=${jobFileError}`
                  );
                }}
                type='text'
                icon={<DownloadOutlined />}
              ></Button>
            </li>
          </ul>
        </Col>
        <Col
          span={10}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Progress
            type='circle'
            percent={jobStatus == JOB_STATUS.FINISHED[0] ? 100 : jobProgress}
            width={90}
            status={
              jobStatus == JOB_STATUS.KILLED[0]
                ? 'exception'
                : jobStatus == JOB_STATUS.FINISHED[0]
                ? 'success'
                : 'normal'
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Section;
