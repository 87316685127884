import { usePrivilege } from '@cms/tpl';
import { Checkbox, Col, Collapse, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import UTILS from 'utils';
import ExportService from '../Services';
import './Headers.scss';
const { Panel } = Collapse;

const Headers = ({ container, colsIfNotViewAll = [], ...props }) => {
  const [data, setData] = useState([]);
  const isViewAll = usePrivilege('ViewAll');
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const buildHeader = () => {
    ExportService(container)
      .getHeaders()
      .then((data) => {
        setData(data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    if (colsIfNotViewAll.length == 0) {
      buildHeader();
    } else {
      if (isViewAll) {
        buildHeader();
      } else {
        const cols = colsIfNotViewAll.map((e) => {
          return { label: e, value: e };
        });
        setData(cols);
        setLoading(false);
      }
    }
  }, []);
  const onCheckAllChange = (e) => {
    if (!e.target.checked) {
      props.onChange();
    } else {
      props.onChange(data.map((d) => d.value));
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <div key='headers' style={{ paddingLeft: '4em' }}>
      <Spin indicator={UTILS.CLASS.LOADER} spinning={loading}>
        <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
          <Panel
            style={{ width: '100%' }}
            header='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chọn tất cả cột'
            key='1'
            collapsible='disabled'
            forceRender={true}
            extra={
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              ></Checkbox>
            }
          >
            <Checkbox.Group
              onChange={(val) => {
                props.onChange(val);
                setIndeterminate(true);
              }}
              value={props.value}
              style={{ width: '100%' }}
            >
              <Row>
                {data.map((entry, i) => {
                  return (
                    <Col key={i} span={6}>
                      <Checkbox value={entry.value}>{entry.label}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Panel>
        </Collapse>
      </Spin>
    </div>
  );
};

export default React.memo(Headers);
