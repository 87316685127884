import { DatePicker, EnumSelectbox, SelectSuggest } from '@cms/tpl';
import { CONTAINER } from 'common';
import ENUMS from 'enums';
import React from 'react';
import OptionService from 'services/OptionService';
import { COMMON_ELEMENTS } from '../Utils';
import ExportCommonIndex from '../index';

const elements = () => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Hợp đồng',
          name: 'contractId',
          input: <SelectSuggest suggestApi={OptionService.getContracts} />,
        },
        {
          label: 'Ngày ký bắt đầu',
          name: 'signTimeFrom',
          input: <DatePicker />,
        },
        {
          label: 'Ngày ký kết thúc',
          name: 'signTimeTo',
          input: <DatePicker />,
        },
        {
          label: 'Trạng thái',
          name: 'status',
          input: <EnumSelectbox enums={ENUMS.AddendumStatus} />,
        },
        {
          label: 'File Scan',
          name: 'hasAttachment',
          input: <EnumSelectbox enums={ENUMS.YES_NO} />,
        },
      ],
    },
    COMMON_ELEMENTS(CONTAINER.EXPORT_ADDENDUM),
  ];
  return [{ cols }];
};
const ExportAddendumIndex = () => {
  return (
    <ExportCommonIndex
      formElements={elements()}
      title={'Phụ lục'}
      container={CONTAINER.EXPORT_ADDENDUM}
    />
  );
};

export default ExportAddendumIndex;
