import { TabPortlet } from '@cms/tpl';
import { ICONS } from 'common';
import React from 'react';
import CreateJob from './CreateJob';
import ListJob from './ListJob';

const ExportCommonIndex = ({
  title,
  container,
  formElements,
  hidePreview = false,
}) => {
  return (
    <TabPortlet
      title={'Xuất dữ liệu: ' + title}
      icon={ICONS.BarsOutlined}
      destroyInactiveTabPane
      renderTabs={[
        {
          title: 'Danh sách File',
          body: <ListJob container={container} />,
        },
        {
          title: 'Tạo yêu cầu mới',
          body: (
            <CreateJob
              container={container}
              formElements={formElements}
              hidePreview={hidePreview}
            />
          ),
        },
      ]}
    />
  );
};

export default ExportCommonIndex;
