import { Portlet, TabPortlet } from '@cms/tpl';
import { ICONS } from 'common';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Service from '../Service';
import Detail from './Detail';
import MediaTab from './MediaTab';

const AddendumDetail = ({ created = false }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ addendumId: '', contract: {} });
  const [searchParams, setSearchParams] = useSearchParams();
  const [monitor, setMonitor] = useState({})
  const id = searchParams.get('id');

  useEffect(() => {
    if (!created) {
      setLoading(true);
      Service.get(id)
        .then((resp) => {
          // @ts-ignore
          setData(resp.item);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, monitor]);
  return (
    <React.Fragment>
      {created ? (
        <Portlet title='Tạo phụ lục' icon={ICONS.PlusOutlined}>
          <Detail create={true} />
        </Portlet>
      ) : (
        <TabPortlet
          renderTabs={[
            {
              title: 'Thông tin phụ lục',
              body: (
                <Detail
                  loading={loading}
                  data={data}
                  create={created}
                  monitor={setMonitor}
                />
              ),
            },
            {
              title: 'Danh sách bài hát',
              body: (
                <MediaTab
                  addendumId={data.addendumId}
                  contract={data.contract}
                />
              ),
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default AddendumDetail;
