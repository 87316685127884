import { SelectSuggest } from '@cms/tpl';
import { CONTAINER } from 'common';
import React from 'react';
import OptionService from 'services/OptionService';
import ExportCommonIndex from '../index';
import { COMMON_ELEMENTS } from '../Utils';

const elements = () => {
  const cols = [
    {
      size: 12,
      elements: [
        {
          label: 'Đối tác',
          name: 'businessPartnerIds',
          input: (
            <SelectSuggest suggestApi={OptionService.getBusinessPartners} />
          ),
        },
        {
          label: 'Số hợp đồng',
          name: 'contractId',
          input: <SelectSuggest suggestApi={OptionService.getContracts} />,
        },
        {
          label: 'Nghệ sĩ',
          name: 'artistId',
          input: <SelectSuggest suggestApi={OptionService.getArtists} />,
        },
      ],
    },
    COMMON_ELEMENTS(CONTAINER.EXPORT_MEDIA),
  ];
  return [{ cols }];
};
const ExportMediaIndex = () => {
  return (
    <ExportCommonIndex
      formElements={elements()}
      title={'Media'}
      container={CONTAINER.EXPORT_MEDIA}
    />
  );
};

export default ExportMediaIndex;
