import { FormAdd } from '@cms/tpl';
import { Input } from 'antd';
import { API_PATHS } from 'common';
import React from 'react';
import Service from '../Service';

const UserMappingTab = ({ dataId, type }) => {
  return (
    <React.Fragment>
      {dataId > 0 && (
        <FormAdd
          rowKey='userId'
          rowName='title'
          onSave={(list) => {
            const ids = list.map((d) => d.userId);
            return Service.setMapping(dataId, ids, type);
          }}
          saveAndReload
          showPaging={false}
          loadPaging={false}
          search={{
            title: 'Tìm người quản lý',
            endpoint: API_PATHS.DELEGATE.GET.USERS,
            filterForm: FILTERS,
            tableColumn: COLUMNS,
          }}
          data={{
            title: 'Danh sách đối tác quản lý',
            endpoint: `${API_PATHS.DELEGATE.GET.MAPPING}?id=${dataId}&type=${type}`,
            tableColumn: COLUMNS,
          }}
        />
      )}
    </React.Fragment>
  );
};
const FILTERS = [
  {
    label: 'Tên',
    name: 'displayName',
    input: <Input />,
  },
  {
    label: 'Email',
    name: 'email',
    input: <Input />,
  },
];
const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'userId',
  },
  {
    title: 'Tên',
    dataIndex: 'displayName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
];

export default UserMappingTab;
