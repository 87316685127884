import { SelectOption } from 'components';
import { useEffect, useState } from 'react';

const SelectOptionAPI = ({
  getOptions,
  enableOptionAll = undefined,
  allValue = undefined,
  showSearch = false,
  renderItem = undefined,
  ...props
}) => {
  const [data, setData] = useState([]);

  useEffect(() => getOptions().then((data) => setData(data.items)), []);
  if (showSearch) {
    props.filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
  }
  return (
    <SelectOption
      {...props}
      showSearch={showSearch}
      renderItem={renderItem}
      onChange={(value) => props.onChange(String(value || ''))}
      value={
        props.mode == 'multiple'
          ? props.value?.length > 0
            ? props.value?.split(',').map((item) => String(item))
            : []
          : String(props.value || '')
      }
      enableOptionAll={enableOptionAll}
      allValue={allValue}
      items={data}
    />
  );
};

export default SelectOptionAPI;
