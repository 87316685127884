import { Tag } from 'antd';
import React from 'react';

const EnumTag = ({ enums = {}, value = undefined }) => {
  const properties = Object.keys(enums);
  const prop = properties.filter((i) => enums[i][0] == Number(value));

  return prop.length > 0 ? (
    <Tag
      color={
        // @ts-ignore
        enums[prop][2] || 'gray'
      }
    >
      {
        // @ts-ignore
        enums[prop][1] || prop
      }
    </Tag>
  ) : (
    <Tag color='gray'>Không xác định</Tag>
  );
};

export default EnumTag;
