import AddendumMGZCType from './AddendumMGZCType';
import AddendumSignType from './AddendumSignType';
import AddendumStatus from './AddendumStatus';
import BOOL from './Bool';
import BusinessPartnerType from './BusinessPartnerType';
import ContractBusinessType from './ContractBusinessType';
import ContractMGType from './ContractMGType';
import ContractSignType from './ContractSignType';
import ContractStatus from './ContractStatus';
import ContractType from './ContractType';
import DistributeType from './DistributeType';
import LANGUAGE from './Language';
import MediaStatus from './MediaStatus';
import MonopolyType from './MonopolyType';
import ON_OFF from './OnOff';
import PlatformType from './PlatformType';
import Status from './Status';
import YES_NO from './YesNo';

const ENUMS = {
  BOOL,
  DistributeType,
  LANGUAGE,
  ON_OFF,
  MonopolyType,
  YES_NO,
  BusinessPartnerType,
  Status,
  ContractType,
  ContractBusinessType,
  ContractSignType,
  ContractMGType,
  MediaStatus,
  AddendumSignType,
  AddendumMGZCType,
  ContractStatus,
  AddendumStatus,
  PlatformType,
};

export default ENUMS;
