import { FormAdd } from '@cms/tpl';
import { Input, Switch } from 'antd';
import { API_PATHS } from 'common';
import ENUMS from 'enums';
import React, { useState } from 'react';
import UTILS from 'utils';
import Service from '../Service';

const DelegateMappingTab = ({ dataId, type, delegateId }) => {
  const [delegate, setDelegate] = useState(delegateId);
  const handlePickDelegate = (isPicked, delegateId) => {
    setDelegate(delegateId);
    Service.setPickDelegate(dataId, delegateId, isPicked);
  };
  return (
    <React.Fragment>
      {dataId > 0 && (
        <FormAdd
          rowKey='delegateId'
          rowName='email'
          onSave={(list) => {
            const ids = list.map((d) => d.delegateId);
            return Service.setMapping(dataId, ids, type);
          }}
          saveAndReload
          showPaging={false}
          loadPaging={false}
          search={{
            title: 'Tìm người đại diện',
            endpoint: API_PATHS.BUSSINESS_PARTNER.GET.DELEGATES,
            filterForm: FILTERS,
            tableColumn: COLUMNS_SEARCH,
          }}
          data={{
            title: 'Danh sách người đại diện',
            endpoint: `${API_PATHS.BUSSINESS_PARTNER.GET.MAPPING}?id=${dataId}&type=${type}`,
            tableColumn: COLUMNS(handlePickDelegate, delegate),
          }}
        />
      )}
    </React.Fragment>
  );
};
const FILTERS = [
  {
    label: 'Tên',
    name: 'name',
    input: <Input />,
  },
  {
    label: 'Email',
    name: 'email',
    input: <Input />,
  },
];
const COLUMNS_SEARCH = [
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.Status, status, 2);
    },
  },
];
const COLUMNS = (handlePickDelegate, delegateId) => [
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      return UTILS.ENUM.findByValue(ENUMS.Status, status, 2);
    },
  },
  {
    title: 'Chọn đại diện',
    dataIndex: 'picked',
    render: (picked, entry) => {
      return (
        <Switch
          defaultChecked={delegateId == entry.delegateId}
          onChange={(value) => handlePickDelegate(value, entry.delegateId)}
        />
      );
    },
  },
];

export default DelegateMappingTab;
