import { ModalFormData } from '@cms/tpl';
import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { ICONS } from 'common';
import MonthPicker from 'components/monthpicker/MonthPicker';
import React, { useState } from 'react';
import Service from '../Services';
import './index.scss';
import ListJob from './ListJob';

const I_MEDIA_REVENUE_TYPE = 11;
const I_CONTRACT_REVENUE_TYPE = 12;
const RevenueCalcIndex = () => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(I_MEDIA_REVENUE_TYPE);
  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <Card
        className='debt-card'
        title={
          <Row align='middle' gutter={9}>
            <Col>
              <Button
                type='primary'
                onClick={() => {
                  form.setFieldValue('type', I_MEDIA_REVENUE_TYPE);
                  setType(I_MEDIA_REVENUE_TYPE);
                  setVisible(true);
                }}
                icon={ICONS.SettingOutlined}
              >
                Tính doanh thu bài hát
              </Button>
            </Col>
          </Row>
        }
      >
        <ListJob type={I_MEDIA_REVENUE_TYPE} />
      </Card>
      <Divider />
      <Card
        className='debt-card'
        title={
          <Row align='middle' gutter={9}>
            <Col>
              <Button
                type='primary'
                onClick={() => {
                  form.setFieldValue('type', I_CONTRACT_REVENUE_TYPE);
                  setType(I_CONTRACT_REVENUE_TYPE);
                  setVisible(true);
                }}
                icon={ICONS.SettingOutlined}
              >
                Tính doanh thu hợp đồng
              </Button>
            </Col>
          </Row>
        }
      >
        <ListJob type={I_CONTRACT_REVENUE_TYPE} />
      </Card>

      <ModalFormData
        name='import-data'
        title={
          type == I_MEDIA_REVENUE_TYPE
            ? 'Tính doanh thu bài hát'
            : 'Tính doanh thu hợp đồng'
        }
        key='import-data'
        visible={visible}
        destroyOnClose
        onVisible={setVisible}
        okText='Xác nhận'
        onSubmit={Service.setJob}
        form={form}
        items={[
          {
            label: 'Tháng',
            name: 'month',
            input: <MonthPicker />,
          },
          {
            name: 'type',
            input: <Input />,
            hidden: true,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default RevenueCalcIndex;
