import { FormFilter, Portlet, PortletTable, SelectSuggest } from '@cms/tpl';
import { Button, Form, Input, Switch } from 'antd';
import { API_PATHS, CONTAINER, ICONS } from 'common';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import FORMAT from 'utils/Format';

const DebtMGZingchartIndex = () => {
  const [form] = Form.useForm();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Portlet title='Bộ lọc MG Zingchart' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách MG Zingchart'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        hideSelectedRow
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.DEBT_MG_ZINGCHART.GET.PAG,
          filterByQs: true,
        }}
        columns={showAll ? COLUMNS_ALL : COLUMNS}
        extendedAction={[
          <Switch
            checkedChildren='Xem rút gọn'
            unCheckedChildren='Xem tất cả'
            onChange={setShowAll}
          />,
          <Button
            type='ghost'
            icon={ICONS.UploadOutlined}
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              urlSearchParams.append('tab', '1');
              navigate(
                `/${
                  CONTAINER.EXPORT_DEBT_MG_ZINGCHART
                }?${urlSearchParams.toString()}`
              );
            }}
          >
            Xuất dữ liệu
          </Button>,
        ]}
      />
    </React.Fragment>
  );
};

const FILTERS = [
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractNumber',
    input: <Input />,
  },
  {
    label: 'Đối tác',
    name: 'businessPartnerId',
    input: (
      <SelectSuggest
        allowSearchEmpty
        suggestApi={OptionService.getBusinessPartners}
      />
    ),
  },
];
const COLUMNS = [
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Tên bài hát',
    dataIndex: 'mediaName',
  },
  {
    title: 'Nghệ sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Đối tác',
    dataIndex: 'businessPartnerName',
  },
  {
    title: 'Hợp đồng',
    dataIndex: 'contractNumber',
  },
  {
    title: 'Phụ lục',
    dataIndex: 'addendumNumber',
  },
  {
    title: 'Ngày hiệu lực',
    dataIndex: 'startTime',
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: 'endTime',
  },
  {
    title: 'Hạng',
    dataIndex: 'rankZc',
  },
  {
    title: 'Số tiền',
    dataIndex: 'mg',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Đã cấn trừ',
    dataIndex: 'calcDeduct',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Công nợ còn lại',
    dataIndex: 'calcRemainingDebt',
    render: (value) => FORMAT.numberWithCommas(value),
  },
];

const COLUMNS_ALL = [
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Tên bài hát',
    dataIndex: 'mediaName',
  },
  {
    title: 'Nghệ sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Đối tác',
    dataIndex: 'businessPartnerName',
  },
  {
    title: 'Hợp đồng',
    dataIndex: 'contractNumber',
  },
  {
    title: 'Phụ lục',
    dataIndex: 'addendumNumber',
  },
  {
    title: 'Ngày hiệu lực',
    dataIndex: 'startTime',
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: 'endTime',
  },
  {
    title: 'Hạng',
    dataIndex: 'rank',
  },
  {
    title: 'Số tiền',
    dataIndex: 'mg',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Số E-Form',
    dataIndex: 'eForm',
  },
  {
    title: 'Cấn trừ đã nhập',
    dataIndex: 'debtReduction',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Doanh thu các tháng',
    dataIndex: 'monthRevenues',
    ellipsis: true,
    render: (monthRevenues) => {
      return (
        <div
          style={{
            overflow: 'auto',
            maxHeight: '50px',
          }}
        >
          {Object.entries(monthRevenues).map((data) => {
            return (
              <li>
                {data[0]} : {data[1]}
              </li>
            );
          })}
        </div>
      );
    },
  },
  {
    title: 'Đã cấn trừ',
    dataIndex: 'calcDeduct',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Công nợ còn lại',
    dataIndex: 'calcRemainingDebt',
    render: (value) => FORMAT.numberWithCommas(value),
  },
];
export default DebtMGZingchartIndex;
