const OPTION = {
  getYesNoOptions: (isAll) => {
    let rs = [
      { value: '0', text: 'Không' },
      { value: '1', text: 'Có' },
    ];

    return isAll ? [{ value: '-1', text: 'Tất cả' }, ...rs] : rs;
  },

  getStatusOptions: (isAll) => {
    let rs = [
      { value: '1', text: 'Hiện thị' },
      { value: '0', text: 'Ẩn' },
    ];

    return isAll ? [{ value: '-1', text: 'Tất cả' }, ...rs] : rs;
  },
};

export default OPTION;
