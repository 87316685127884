import {
  CustomerServiceOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import {
  EnumSelectbox,
  FormFilter,
  Portlet,
  PortletTable,
  SelectSuggest,
} from '@cms/tpl';
import { Button, Card, Col, Form, Input, Row, Statistic } from 'antd';
import { API_PATHS, CONTAINER, ICONS } from 'common';
import MonthPicker from 'components/monthpicker/MonthPicker';
import ENUMS from 'enums';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionService from 'services/OptionService';
import UTILS from 'utils';
import FORMAT from 'utils/Format';
import Service from './Service';

const MediaRevenueIndex = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tableMonitor, setTableMonitor] = useState({});
  const [data, setData] = useState({ items: [], total: 0 });
  const [total, setTotal] = useState({ listen: 0, revenue: 0 });
  useEffect(() => {
    Service.getTotal(location.search).then((resp) => {
      // @ts-ignore
      setTotal(resp.item);
    });
  }, [location.search]);
  return (
    <React.Fragment>
      <Row className='stats-row' gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng doanh thu chia sẻ`}
              value={total.revenue}
              valueStyle={{ color: 'rgb(34, 145, 169)' }}
              prefix={<MoneyCollectOutlined />}
              suffix='VNĐ'
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={`Tổng lượt nghe`}
              value={total.listen}
              valueStyle={{ color: 'rgb(147, 28, 143)' }}
              prefix={<CustomerServiceOutlined />}
              suffix=''
            />
          </Card>
        </Col>
      </Row>
      <Portlet title='Lọc Media' icon={ICONS.SearchOutlined}>
        <FormFilter
          autoFetch
          name='form-filter'
          form={form}
          items={FILTERS}
          mapURLSearchParams={true}
          setTableMonitor={setTableMonitor}
        />
      </Portlet>
      <PortletTable
        title='Danh sách Media'
        icon={ICONS.BarsOutlined}
        tableMonitor={tableMonitor}
        onTableMonitor={() => setTableMonitor({})}
        hideSelectedRow
        source={{
          data: data,
          onChangeData: setData,
          endpoint: API_PATHS.MEDIA_REVENUE.GET.PAG,
          filterByQs: true,
        }}
        columns={COLUMNS}
        extendedAction={[
          <Button
            type='ghost'
            icon={ICONS.UploadOutlined}
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              urlSearchParams.append('tab', '1');
              navigate(
                `/${
                  CONTAINER.EXPORT_MEDIA_REVENUE
                }?${urlSearchParams.toString()}`
              );
            }}
          >
            Xuất dữ liệu
          </Button>,
        ]}
      />
    </React.Fragment>
  );
};

const FILTERS = [
  {
    label: 'Tháng',
    name: 'month',
    input: <MonthPicker />,
  },
  {
    label: 'Mixus ID',
    name: 'mediaId',
    input: <Input />,
  },
  {
    label: 'Kênh phân phối',
    name: 'distributeType',
    input: <EnumSelectbox enums={ENUMS.DistributeType} />,
  },
  {
    label: 'Số hợp đồng',
    name: 'contractNumber',
    input: <Input />,
  },
  {
    label: 'Đối tác',
    name: 'businessPartnerId',
    input: (
      <SelectSuggest
        allowSearchEmpty
        suggestApi={OptionService.getBusinessPartners}
      />
    ),
  },
];
const COLUMNS = [
  {
    title: 'Tháng',
    dataIndex: 'month',
  },
  {
    title: 'Phân phối',
    dataIndex: 'distributeType',
    render: (value) => UTILS.ENUM.findByValue(ENUMS.DistributeType, value, 2),
  },
  {
    title: 'Mixus ID',
    dataIndex: 'mediaId',
  },
  {
    title: 'Tên bài hát',
    dataIndex: ['media', 'title'],
  },
  {
    title: 'Nghệ sĩ',
    dataIndex: 'artistName',
  },
  {
    title: 'Số hợp đồng',
    dataIndex: 'contractNumber',
  },
  {
    title: 'Số phụ lục',
    dataIndex: 'addendumNumber',
  },
  {
    title: 'Đối tác',
    dataIndex: ['businessPartner', 'legalName'],
  },
  {
    title: 'Lượt nghe',
    dataIndex: 'listen',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Doanh thu',
    dataIndex: 'revenue',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Doanh thu cam kết',
    dataIndex: 'monthRevenue',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Doanh thu chia sẻ',
    dataIndex: 'shareRevenue',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'MG còn lại',
    dataIndex: 'debtReduction',
    render: (value) => FORMAT.numberWithCommas(value),
  },
  {
    title: 'Tiền tệ',
    dataIndex: '',
    render: () => 'VNĐ',
  },
  {
    title: 'Tình trạng thanh toán',
    dataIndex: 'paymentStatusNames',
  },
  {
    title: 'Loại hợp đồng',
    dataIndex: ['businessPartner', 'type'],
    render: (type) =>
      UTILS.ENUM.findByValue(ENUMS.BusinessPartnerType, type, 1),
  },
];

export default MediaRevenueIndex;
