import { HELPER } from '@cms/tpl';
import { API_PATHS } from 'common';
import UTILS from 'utils';

const ImportService = (container) => {
  return {
    getPag: (search) =>
      HELPER.API.executeGet(`${API_PATHS.IMPORT(container).GET.PAG}${search}`),
    add: (id, entity) => {
      let data = new URLSearchParams();
      var params = {};
      params.linkData = entity.linkData;
      params.data = entity;
      delete params.data?.linkData;
      data.append(
        'entity',
        JSON.stringify(UTILS.FORMAT.removeJsonValueEmpty(params))
      );
      return HELPER.API.executePostForm(
        API_PATHS.IMPORT(container).POST.ADD,
        data
      );
    },
    getHeaders: () =>
      HELPER.API.executeGet(`${API_PATHS.IMPORT(container).GET.HEADERS}`),
    kill: (jobId) => {
      let data = new URLSearchParams();
      data.append('jobId', jobId);
      return HELPER.API.executePostForm(
        API_PATHS.IMPORT(container).POST.KILL,
        data
      );
    },
    resume: (jobId) => {
      let data = new URLSearchParams();
      data.append('jobId', jobId);
      return HELPER.API.executePostForm(
        API_PATHS.IMPORT(container).POST.RESUME,
        data
      );
    },
  };
};

export default ImportService;
