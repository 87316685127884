const COLOR = {
  HIDE: '#8c8c8c',
  SHOW: '#2196f3',
  SHOW_V2: '#69c0ff',
  PUBLIC: '#af52bf',
  SAVE: '#4DBCD4',
  DELETE: '#ff1744',
  FAIL: '#ff1744',
  REJECT: '#ff0000',
  APPROVE: '#009d00',
  PENDING: '#F29339',
  REFRESH: '#49964A',
  PROCESSING: '#FFE731',
};

export default COLOR;
