import { Select } from 'antd';
const { Option } = Select;
const SelectOption = ({
  items,
  enableOptionAll,
  allValue,
  renderItem,
  ...props
}) => {
  return (
    <Select
      optionLabelProp={renderItem ? 'label' : undefined}
      {...props}
      allowClear={
        typeof props.allowClear != 'undefined' ? props.allowClear : true
      }
    >
      {enableOptionAll ? (
        allValue ? (
          <Option value={allValue.value}>{allValue.label}</Option>
        ) : (
          <Option value={'-1'}>Tất cả</Option>
        )
      ) : undefined}
      {items.map((data) => (
        <Option
          key={data.value}
          value={String(data.value)}
          label={data.label}
          disabled={data.disabled ? true : false}
        >
          {renderItem ? renderItem(data) : data.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectOption;
